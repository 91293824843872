import React from 'react';

const Pagination = ({ currentPage, totalPages, onPageChange, entriesPerPage, onEntriesPerPageChange }) => {
  return (
    <div className="bg-white px-4 py-5 border-t border-gray-200">
      <div className="flex items-center justify-between">
        {/* Entries per page selector */}
        <div className="flex items-center gap-2">
          <select
            value={entriesPerPage}
            onChange={(e) => onEntriesPerPageChange(Number(e.target.value))}
            className="border border-gray-300 rounded-md text-sm py-1 px-2 focus:ring-2 focus:ring-[#03254c] focus:border-transparent"
          >
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
          <span className="text-sm text-gray-600">Entries per page</span>
        </div>

        {/* Centered pagination controls */}
        <div className="flex-1 flex justify-center">
          <div className="flex items-center gap-1">
            <button
              onClick={() => onPageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="px-2 py-1 rounded-md text-sm border border-gray-300 disabled:bg-gray-100 disabled:text-gray-400"
            >
              <svg className="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
              </svg>
            </button>

            {[...Array(totalPages)].map((_, index) => {
              const pageNumber = index + 1;
              const shouldShow = pageNumber === 1 || 
                               pageNumber === totalPages || 
                               Math.abs(pageNumber - currentPage) <= 2;

              if (!shouldShow) {
                if (pageNumber === currentPage - 3 || pageNumber === currentPage + 3) {
                  return <span key={index} className="px-3 py-1">...</span>;
                }
                return null;
              }

              return (
                <button
                  key={index}
                  onClick={() => onPageChange(pageNumber)}
                  className={`px-3 py-1 rounded-md text-sm font-medium
                    ${currentPage === pageNumber
                      ? 'bg-[#03254c] text-white'
                      : 'text-gray-700 hover:bg-gray-50 border border-gray-300'
                    }`}
                >
                  {pageNumber}
                </button>
              );
            })}

            <button
              onClick={() => onPageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="px-2 py-1 rounded-md text-sm border border-gray-300 disabled:bg-gray-100 disabled:text-gray-400"
            >
              <svg className="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
              </svg>
            </button>
          </div>
        </div>

        {/* Empty div for flex spacing */}
        <div className="w-[200px]"></div>
      </div>
    </div>
  );
};

export default Pagination;