export const textData = {
  VENTRICULOMEGALY: "Ventriculomegaly",
  DIAGNOSESNAME: "Borderline Ventriculomegaly / Ventriculomegaly",
  NOTCLICKABLESYNDROMES: [
    "Encephalocele",
    "Cystic Hygroma",
    "Diaphragmatic Hernia",
    "C CAM",
    "Skeletal Dysplasia",
    "Body Stalk syndrome",
  ],
};

export const ventriculomegalyDdescription =
  "Many causes can lead to Ventriculomegaly.Most common R Fetal Chromosomal defect, Single gene disorders, Fetal infection, Fetal stroke, and many more conditions can lead to Ventriculomegaly";

export const nuchalFoldThickeningDescription =
  "Nuchal Fold Thickening is diagnosed in the second trimester.It is commonly associated with chromosomal abnormalities, cardiac abnormalities, fetal anemia, fetal infection, and many single-gene disorders. it can also be idiopathic. it can be associated with the following disorders.";

export const fetalGraphReference = {
  abstract: "Abstract",
  header:
    "EP05.08: Fetal biometry and growth charts: Indian population - Phase II",
  author: "P. Acharya",
  publish: "First published: 15 October 2018 | ",
  url: "https://doi.org/10.1002/uog.19838",
};

export const transCerebellarDiameter = {
  abstract: "Obstet Gynecol. 1990 Jun; 75(6):981-5",
  header:
    "The transverse cerebellar diameter in estimating gestational age in the large for gestational age fetus.",
  publish: "Hill LM, Guzick D, Fries J, Hixson J, Riveloo D",
};

export const ductusVenosus = {
  abstract:
    "Ultrasound Obstet Gynecol 2006; 28; 890-898 <br> Published online 9 November 2006 in Wiley InterScience (<a class='text-blue-700' href='https://doi.org/10.1002/uog.3857' target='_blank'>https://doi.org/10.1002/uog.3857</a>)",
  header:
    "Longitudinal reference ranges for dectus venosus flow velocities and waveform indices",
  author: "J. KESSLER, S. RASMUSSEN, M. HANSON and T. KISERUD",
};

export const uterineArtery = {
  abstract:
    "Ultrasound Obstet Gynecol 2008; 32; 128-132 <br> Published online 6 November 2008 in Wiley InterScience (<a class='text-blue-700' href='https://doi.org/10.1002/uog.5315' target='_blank'>https://doi.org/10.1002/uog.5315</a>)",
  header:
    "Reference ranges for uterine artery mean pulsatility index at 11-41 weeks of gestation",
  author:
    "O. GEMEX, F. FIGUERAS, S. FERNANDEZ, M. BENNASAR, J.M. MARTINEZ, B. PUERTO and E. GRATACOS",
};

export const SLVPandAFI = {
  abstract: "Am J Obstet Gynecol. 2000 Jun; 182(6): 1581-8.",
  header:
    "The amniotic fluid index, single deepest pocket, and two-diameter pocket in normal human pregnacy. (<a class='text-blue-700' href='https://doi.org/10.1067/mob.2000.107325' target='_blank'>https://doi.org/10.1067/mob.2000.107325</a>)",
  author: "Magann EF, Sanderson M, Martin JN, Chauhan S",
};

export const UAandMCAandCPR = {
  abstract:
    "Ultrasound Obstet Gynecol 2003; 21: 124–127 Published online 13 January 2003 in Wiley InterScience (<a class='text-blue-700' href='https://doi.org/10.1002/uog.20' target='_blank'>https://doi.org/10.1002/uog.20</a>)",
  header: "The cerebroplacental Doppler ratio revisited",
  author:
    "A. A. BASCHAT* and U. GEMBRUCH†  *Department of Obstetrics, Gynecology and Reproductive Sciences, University of Maryland, Baltimore, MD, USA and †Department of Prenatal Medicine and Obstetrics, Friedrich-Wilhelm University, Bonn, Germany",
};

export const nasalbonelength = {
  abstract:
    "Foetal Nasal Bone Length Measurement and Evolving Normogram in Southern Indian Population in Normal Pregnancies: A Cross-sectional Study (<a class='text-blue-700' href='https://jcdr.net/article_fulltext.asp?issn=0973-709x&year=2021&volume=15&issue=3&page=TC21&issn=0973-709x&id=14693' target='_blank'>https://jcdr.net/article_fulltext.asp?issn=0973-709x&year=2021&volume=15&issue=3&page=TC21&issn=0973-709x&id=14693</a>)",
  header: "Nasal Bone Length Measurement",
  author:
    "RB Prakash Jain, Sanket M Kotnis, HN Roopa",
};

export const intracranialtranslucencies = {
  abstract:
    "Design of Standard Value Intracranial Translucency of Fetus in Early Pregnancy Using Ultrasound(<a class='text-blue-700' href='https://www.semanticscholar.org/paper/Design-of-Standard-Value-Intracranial-Translucency-Yang-Kim/1cf99b4ab78bcee55f5bdf146eb3156afddfec36/figure/4' target='_blank'>https://www.semanticscholar.org/paper/Design-of-Standard-Value-Intracranial-Translucency-Yang-Kim/1cf99b4ab78bcee55f5bdf146eb3156afddfec36/figure/4</a>)",
  header: " Percentile values of intracranial translucency diameter by gestational age ",
  author:
    "Sung-Hee Yang, changsoos Kim",
};

export const nuchaltranslucencies = {
  abstract:
    "Reference values of nuchal translucency thickness in a Brazilian population sample: experience from a single center (<a class='text-blue-700' href='https://www.semanticscholar.org/paper/Reference-values-of-nuchal-translucency-thickness-a-J%C3%BAnior-Pires/4d782984201ebb27766eab6e17adf09261c28f29/figure/2' target='_blank'>https://www.semanticscholar.org/paper/Reference-values-of-nuchal-translucency-thickness-a-J%C3%BAnior-Pires/4d782984201ebb27766eab6e17adf09261c28f29/figure/2</a>)",
  header: "Estimated percentiles of nuchal translucency thickness (mm) for crown-rump length (mm)",
  author:
    "E.A Junior, C.R. Pires, W. Martins, L. Nardozza, S.M.Z Filho",
};
export const crownRumpLength = {
  abstract: "Ultrasound Obstet Gynecol 2008; 31: 388-396",
  header: "Crown-rump length measurement in early pregnancy",
  author: "L. J. SALOMON, M. DUYME, J. P. BERNARD and Y. VILLE",
};

export const tableHeaderName = {
  bpd: "BIPARIETAL DIAMETER",
  hc: "HEAD CIRCUMFERENCE",
  ac: "ABDOMINAL CIRCUMFERENCE",
  fl: "FEMUR LENGTH",
  efw: "EFW",
  tcd: "TRANS CEREBELLAR DIAMETER",
  dv: "DUCTUS VENPSUS PI",
  uterine: "UTERINE ARTERY",
  slvp: "SINGLE LARGEST VERTICAL POCKET",
  afi: "AMNIOTIC FLUID INDEX",
  ua: "UMBILICAL ARTERY",
  mca: "MIDDLE CEREBRAL ARTERY",
  cpr: "CEREBROPLACENTAL_RATIO",
  nb: "NASCAL BONE LENGTH",
  it: "INTERCRANIAL TRANSLUCENCY",
  nt: "NUCHAL TRANSLUCENCY", 
  crl: "CROWN RUMP LENGTH",
};

export const welcomePageContent = {
  welcomeText: "Welcome to ScanOFe",
  tagline:
    "We aim to provide step-by-step guidance to assist you in reaching appropriate diagnosis of IUGR babies and deciding when to deliver.",
  buttonText: "Start Examination",
};

export const clickableDiagnoses = [
  " Hypoplastic / Absent Nasal Bone",
  "Agenesis Of The Corpus Callosum",
  "Borderline Ventriculomegaly / Ventriculomegaly",
  "Increased NT (Nuchal Translucency)",
  "Nuchal Fold Thickening",
];

export const confirmMessage = "Are you sure about updating your information?"
