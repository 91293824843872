import axios from 'axios';

// const STRAPI_URL = "https://uat-api.scanofe.com/api";
const STRAPI_URL = process.env.REACT_APP_API_KEY;

class TemplateService {
  static getCurrentProfileId() {
    const profileData = localStorage.getItem('profileId');
    return profileData ? JSON.parse(profileData) : null;
  }


  async createOrUpdate(Data) {
    try {
      const profileId = TemplateService.getCurrentProfileId();
      
      if (!profileId) {
        throw new Error('No profile ID found');
      }

      if (!Data) {
        throw new Error('No patient data provided');
      }

      const result = await this.createTemplate(profileId, Data);
      return result;
    } catch (error) {
      console.error('Error in createOrUpdatePatient:', error.response?.data || error);
      throw error;
    }
  }

  async createSpecialelement(Data) {
    try {
      const profileId = TemplateService.getCurrentProfileId();
      
      if (!profileId) {
        throw new Error('No profile ID found');
      }

      if (!Data) {
        throw new Error('No patient data provided');
      }

      const result = await this.createSpecialElement(profileId, Data);
      return result;
    } catch (error) {
      console.error('Error in createOrUpdatePatient:', error.response?.data || error);
      throw error;
    }
  }

  // Fetch custom templates for a profile
  async getCustomTemplates(profileId) {
    try {
      const response = await fetch(`${STRAPI_URL}/custom-templates?filters[scanofe_user][id][$eq]=${profileId}`);
      // const response = await axios.fetch(`${STRAPI_URL}/templates`, {
      //   params: {
      //     'filters[scanofe_user][id][$eq]': profileId,
      //     // 'sort[0]': 'createdAt:desc', 
      //     populate: '*'
      //   }
      // });
      const data = await response.json();
      return data.data.map(item => ({
        id: item.id,
        name: item.attributes.name,
        content: item.attributes.content,
        scanofe_user: item.attributes.profileId
      }));
    } catch (error) {
      console.error('Error fetching custom templates:', error);
      return [];
    }
  }

  // Fetch special elements for a profile
  async getSpecialElements(profileId) {
    try {
      const response = await fetch(`${STRAPI_URL}/special-elements?filters[scanofe_user][id][$eq]=${profileId}`);
      const data = await response.json();

      console.log("response----------", data);

      return data.data.map(item => ({
        specialElementId: item.id, 
        id: item.attributes.elementType,
        name: item.attributes.name || item.attributes.elementType,
        content: item.attributes.content,
        scanofe_user: item.attributes.profileId
      }));
    } catch (error) {
      console.error('Error fetching special elements:', error);
      return [];
    }
  }

  async getPredefinedTemplates() {
    
    try {
      const response = await axios.get(`${STRAPI_URL}/pre-defined-templates`, {
        
      });
  
      return response.data.data.map(item => ({
        id: item.id,
        name: item.attributes.name,
        content: item.attributes.content
      }));
    } catch (error) {
      console.error('Error fetching predefined templates:', error);
      throw error;
    }
  }


  async getUserSpecialElements() {
    const profileId = this.getCurrentProfileId(); // Get the current profile ID
    try {
      const response = await axios.get(`${STRAPI_URL}/special-elements`, {
        params: {
          'filters[scanOfUser]': profileId, // Filter special elements by profile ID
          'populate': '*'
        }
      });
  
      // Map the response to include elementType
      return response.data.data.map(item => ({
        id: item.id,
        elementType: item.attributes.elementType, // Ensure elementType is included
        content: item.attributes.content
      }));
    } catch (error) {
      console.error('Error fetching user special elements:', error);
      throw error;
    }
  }

  async createTemplate(profileId, templateData) {
    try {
      console.log('Creating template with data:', templateData);
      
      if (!templateData.name || !templateData.content) {
        throw new Error('Template name and content are required');
      }

      const response = await axios.post(`${STRAPI_URL}/custom-templates`, {
        data: {
          name: templateData.name,
          content: templateData.content,
          isCustom: true,
          scanofe_user: profileId,
          publishedAt: new Date().toISOString()
        }
      });

      console.log('Create template response:', response.data);

      if (!response.data || !response.data.data) {
        throw new Error('Invalid response format from server');
      }

      return {
        id: response.data.data.id,
        name: response.data.data.attributes.name,
        content: response.data.data.attributes.content,
        isCustom: response.data.data.attributes.isCustom,
        scanofe_user: response.data.data.attributes.scanofe_user
      };
    } catch (error) {
      console.error('Detailed error in createTemplate:', {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status
      });
      throw new Error(`Failed to create template: ${error.message}`);
    }
  }

  async updateTemplate(templateId, templateData) {
    try {
      const response = await axios.put(`${STRAPI_URL}/custom-templates/${templateId}`, {
        data: {
          name: templateData.name,
          content: templateData.content
        }
      });

      return {
        id: response.data.data.id,
        name: response.data.data.attributes.name,
        content: response.data.data.attributes.content,
        isCustom: response.data.data.attributes.isCustom
      };
    } catch (error) {
      console.error('Error updating template:', error);
      throw error;
    }
  }

  async deleteTemplate(templateId) {
    try {
      await axios.delete(`${STRAPI_URL}/custom-templates/${templateId}`);
      return true;
    } catch (error) {
      console.error('Error deleting template:', error);
      throw error;
    }
  }

  async createSpecialElement(profileId, elementData) {
    try {
      
      
      if (!elementData.elementType || !elementData.content) {
        throw new Error('Element type and content are required');
      }

      const response = await axios.post(`${STRAPI_URL}/special-elements`, {
        data: {
          elementType: elementData.elementType,
          content: elementData.content,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
          publishedAt: new Date().toISOString(),
          scanofe_user: profileId 
        }
      });

      console.log('Create special element response:', response.data);

      if (!response.data || !response.data.data) {
        throw new Error('Invalid response format from server');
      }

      return {
        id: response.data.data.id,
        elementType: response.data.data.attributes.elementType,
        content: response.data.data.attributes.content
      };
    } catch (error) {
      console.error('Detailed error in createSpecialElement:', {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status
      });
      throw new Error(`Failed to create special element: ${error.message}`);
    }
  }


  async updateSpecialElement(elementId, elementData) {
    try {
      const response = await axios.put(`${STRAPI_URL}/special-elements/${elementId}`, {
        data: {
          name: elementData.name,
          content: elementData.content,
        }
      });

      return {
        id: response.data.data.id,
        elementType: response.data.data.attributes.elementType,
        content: response.data.data.attributes.content
      };
    } catch (error) {
      console.error('Error updating special element:', error);
      throw error;
    }
  }
  
 
}

export default new TemplateService();