import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Fetal_Biometry_AC,
  Fetal_Biometry_BD,
  Fetal_Biometry_EFW,
  Fetal_Biometry_FL,
  Fetal_Biometry_HC,
  Trans_Cerebellar_Diameter,
  DV,
  Uterine_Artery,
  Oligo_SLVP,
  Oligo_AFI,
  UA,
  MCA,
  CPR,
  NB,
  IT,
  NT,
  CRL,
} from "../utils/tablesData";
import {
  fetalGraphReference,
  transCerebellarDiameter,
  ductusVenosus,
  uterineArtery,
  SLVPandAFI,
  tableHeaderName,
  UAandMCAandCPR,
  intracranialtranslucencies,
  nuchaltranslucencies,
  nasalbonelength,
  crownRumpLength
} from "../utils/constant";
import DynamicTable from "./ShowTable";
import { Line } from "react-chartjs-2";
import { Chart, Filler } from "chart.js";

Chart.register(Filler);

function useScreenWidth(
  isShowTable,
  isShowChart,
  setIsShowTable,
  setIsShowChart
) {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    if (screenWidth < 1370) {
      setIsShowTable(false);
      setIsShowChart(true);
    }
  }, [screenWidth, setIsShowTable]);

  // Add more logic as needed

  return {
    screenWidth,
    isShowTable,
    isShowChart,
  };
}

function GenerateChart({ chart, setChart }) {
  const [chartData, setChartData] = useState({
    graphData: "",
    graphReference: "",
  });
  const [isShowTable, setIsShowTable] = useState(false);
  const [isShowChart, setIsShowChart] = useState(false);

  const { screenWidth } = useScreenWidth(
    isShowTable,
    isShowChart,
    setIsShowTable,
    setIsShowChart
  );
  const showTableButton = screenWidth < 1370;

  const [tableData, setTableData] = useState([]);
  const [tableHeader, setTableHeader] = useState("");

  const chartValue = chart.value;
  const chartWeek = +chart.week;
  const chartType = chart.type;

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: "weeks",
          color: "blue",
        },
      },
      y: {
        display: true,
        title: {
          display: true,
          text: "mm",
          color: "blue",
        },
      },
    },
  };

  const closeModel = () => {
    setChart({ ...chart, showChart: false });
  };

  const getTableDataByName = async (name, week) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_KEY}/${name}?sort[0]=${week}:asc&pagination[page]=1&pagination[pageSize]=100`
    );

    const filteredData = response.data.data.map((obj) => {
      const { createdAt, updatedAt, publishedAt, ...rest } = obj.attributes;
      return { ...obj, attributes: rest };
    });
    setTableData(filteredData);
    return filteredData;
  };

  useEffect(() => {
    switch (chartType) {
      case "BPD":
        setChartData({
          graphData: Fetal_Biometry_BD,
          graphReference: fetalGraphReference,
        });
        getTableDataByName(
          "med-fetal-biometry-biparietal-diameters",
          "C_Weeks"
        );
        setTableHeader(tableHeaderName.bpd);
        break;
      case "HC":
        setChartData({
          graphData: Fetal_Biometry_HC,
          graphReference: fetalGraphReference,
        });
        getTableDataByName("med-fetal-biometry-head-circumferences", "C_Weeks");
        setTableHeader(tableHeaderName.hc);
        break;
      case "AC":
        setChartData({
          graphData: Fetal_Biometry_AC,
          graphReference: fetalGraphReference,
        });
        getTableDataByName(
          "med-fetal-biometry-abdominal-circumferences",
          "C_Weeks"
        );
        setTableHeader(tableHeaderName.ac);
        break;
      case "FL":
        setChartData({
          graphData: Fetal_Biometry_FL,
          graphReference: fetalGraphReference,
        });
        getTableDataByName("med-fetal-biometry-femur-lengths", "C_Weeks");
        setTableHeader(tableHeaderName.fl);
        break;
      case "EFW":
        setChartData({
          graphData: Fetal_Biometry_EFW,
          graphReference: fetalGraphReference,
        });
        getTableDataByName("med-fetal-biometry-efws", "C_Weeks");
        setTableHeader(tableHeaderName.efw);
        break;
      case "TCD":
        setChartData({
          graphData: Trans_Cerebellar_Diameter,
          graphReference: transCerebellarDiameter,
        });
        getTableDataByName("med-trans-cerebellar-diameters", "C_GA_Weeks");
        setTableHeader(tableHeaderName.tcd);
        break;
      case "DV":
        setChartData({
          graphData: DV,
          graphReference: ductusVenosus,
        });
        getTableDataByName(
          "med-ductus-venosus-pi-in-2nd-and-3rd-trimesters",
          "C_GA_Weeks"
        );
        setTableHeader(tableHeaderName.dv);
        break;
      case "Uterine":
        setChartData({
          graphData: Uterine_Artery,
          graphReference: uterineArtery,
        });
        getTableDataByName(
          "med-uterine-artery-mean-pi-95-centiles",
          "C_GA_Weeks"
        );
        setTableHeader(tableHeaderName.uterine);
        break;
      case "SLVP":
        setChartData({
          graphData: Oligo_SLVP,
          graphReference: SLVPandAFI,
        });
        getTableDataByName("med-oligohydramnios-single-lvps", "C_GA_Weeks");
        setTableHeader(tableHeaderName.slvp);
        break;
      case "AFI":
        setChartData({
          graphData: Oligo_AFI,
          graphReference: SLVPandAFI,
        });
        getTableDataByName(
          "med-oligohydramnios-amniotic-fluid-indices",
          "C_GA_Weeks"
        );
        setTableHeader(tableHeaderName.afi);
        break;
      case "UA":
        setChartData({
          graphData: UA,
          graphReference: UAandMCAandCPR,
        });
        getTableDataByName("med-umbilical-arteries", "C_Weeks");
        setTableHeader(tableHeaderName.ua);
        break;
      case "MCA":
        setChartData({
          graphData: MCA,
          graphReference: UAandMCAandCPR,
        });
        getTableDataByName("med-middle-cerebral-arteries", "C_Weeks");
        setTableHeader(tableHeaderName.mca);
        break;
      case "CPR":
        setChartData({
          graphData: CPR,
          graphReference: UAandMCAandCPR,
        });
        getTableDataByName("med-cerebroplacental-ratios", "C_Weeks");
        setTableHeader(tableHeaderName.cpr);
        break;
      case "NB":
        setChartData({
          graphData: NB,
          graphReference: nasalbonelength,
        });
        getTableDataByName("med-nasal-bone-lengths", "C_GA_Weeks");
        setTableHeader(tableHeaderName.nb);
        break;
      case "IT":
        setChartData({
          graphData: IT,
          graphReference: intracranialtranslucencies,
        });
        getTableDataByName("med-intracranial-translucencies", "C_GA_Weeks");
        setTableHeader(tableHeaderName.it);
        break;
      case "NT":
        setChartData({
          graphData: NT,
          graphReference: nuchaltranslucencies,
        });
        getTableDataByName("med-nuchal-translucencies", "C_GA_Weeks");
        setTableHeader(tableHeaderName.nt);
        break;

      case "CRL":
        setChartData({
          graphData: CRL,
          graphReference: crownRumpLength,
        });
        getTableDataByName("med-nasal-bone-lengths", "C_GA_Weeks");
        setTableHeader(tableHeaderName.crl);
        break;
      
      default:
        setChartData(null);
    }
  }, [chartType]);

  useEffect((e) => {
    const close = (e) => {
      if (e.key === "Escape") {
        closeModel();
      }
    };

    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  let chartdata = chartData.graphData;
  let labels = chartdata ? chartdata?.weeks : [];
  let tableLabels = Object.keys(chartdata);

  const borderColors = [
    "rgba(255, 0, 0, 1)",    // Red for upper line
    "rgba(0, 128, 0, 1)",    // Green for middle line
    "rgba(255, 0, 0, 1)",    // Red for lower line
  ];
  const backgroundColor = [
    "rgba(255, 0, 0, 0.2)",  // Red with transparency
    "rgba(0, 128, 0, 0.2)",  // Green with transparency
    "rgba(255, 0, 0, 0.2)",  // Red with transparency
  ];
  let datasets = tableLabels
    .filter((label) => label !== "weeks")
    .map((label, index) => {
      return {
        label,
        data: chartdata[label],
        borderColor: borderColors[index % borderColors.length],
        backgroundColor: backgroundColor[index % backgroundColor.length],
        borderWidth: 2,  // Optional: make lines more visible       
      };
    });


  // // Add area fills between lines and to chart boundaries
  // if (datasets.length >= 2) {
  //   const upperLine = datasets[0].data;
  //   const lowerLine = datasets[datasets.length - 1].data;
  //   const maxY = Math.max(...upperLine) * 1.2; // 20% higher than highest point

  //   // Create background datasets in specific order
  //   const backgroundDatasets = [
  //     // First create the base red background
  //     {
  //       label: 'Background',
  //       data: Array(labels.length).fill(maxY),
  //       fill: true,
  //       backgroundColor: 'rgba(255, 182, 193, 0.3)', // Light red
  //       borderWidth: 0,
  //       pointRadius: 0,
  //       order: 1
  //     },
  //     // Then add the green area between the lines
  //     {
  //       label: 'Normal Range',
  //       data: upperLine,
  //       fill: '+1', // Fill to the next dataset
  //       backgroundColor: 'rgba(144, 238, 144, 0.5)', // Slightly more opaque green
  //       borderWidth: 0,
  //       pointRadius: 0,
  //       order: 2
  //     },
  //     // Add the lower line dataset that the green area will fill to
  //     {
  //       label: 'Lower Boundary',
  //       data: lowerLine,
  //       borderWidth: 0,
  //       pointRadius: 0,
  //       order: 2
  //     }
  //   ];

  //   // Update the options to ensure proper Y-axis scaling
  //   options.scales.y = {
  //     ...options.scales.y,
  //     min: 0,
  //     max: maxY,
  //     grid: {
  //       drawBorder: true,
  //       color: 'rgba(0, 0, 0, 0.1)',
  //     }
  //   };

  //   // Add the original lines on top with increased width for better visibility
  //   const lineDatasets = datasets.map(dataset => ({
  //     ...dataset,
  //     borderWidth: 2,
  //     order: 3, // Make sure lines are drawn on top
  //     fill: false
  //   }));

  //   // Combine all datasets in the correct order
  //   datasets = [...backgroundDatasets, ...lineDatasets];
  // }

  
  const dynamicPointIndex = labels.indexOf(chartWeek);
  const dynamicPointDataset = {
    label: chartType,
    backgroundColor: "red",
    borderColor: "red",
    data: Array(labels.length)
      .fill(null)
      .map((value, index) => (index === dynamicPointIndex ? chartValue : null)),
    pointBackgroundColor: "red",
    pointRadius: 5,
    pointHoverRadius: 10,
    showLine: true,
  };
  let newDataset = chartValue
    ? [...datasets, dynamicPointDataset]
    : [...datasets];

  if (chartData.graphData) {
    const weeks = chartData.graphData.weeks;

    if (chartType === "Uterine") {
      const weeks = chartData.graphData.weeks;
      const c_95th = chartData.graphData.C_95th;
      const maxC95th = Math.max(...c_95th);

      const formattedData = weeks.map((week, index) => ({
        x: week,
        y: Math.round(maxC95th),
      }));
      const newData = {
        label: "Improper Placentation",
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        fill: 2,
        data: formattedData,
        borderWidth: 0,
        radius: 0,
      };
      newDataset = [...newDataset, newData];
    } else if (chartType === "SLVP") {
      const c_95th = chartData.graphData.C_95th;
      const maxC95th = Math.max(...c_95th);

      const formattedDataAbove = weeks.map((week, index) => ({
        x: week,
        y: Math.round(maxC95th) + 1,
      }));

      const formattedDataBelow = weeks.map((week, index) => ({
        x: week,
        y: 1,
      }));

      const newDataAbove = {
        label: "Polyhydramnios",
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        fill: 2,
        data: formattedDataAbove,
        borderWidth: 0,
        radius: 0,
      };

      const newDataBelow = {
        label: "Oligohydramnios",
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        fill: 0,
        data: formattedDataBelow,
        borderWidth: 0,
        radius: 0,
      };
      newDataset = [...newDataset, newDataAbove, newDataBelow];
    } else if (chartType === "UA") {
      const plus2sd = chartData.graphData.C_Plus_2_SD;
      const maxPlus2sd = Math.max(...plus2sd);

      const formattedDataAbove = weeks.map((week, index) => ({
        x: week,
        y: Math.round(maxPlus2sd) + 1,
      }));

      const newDataAbove = {
        label: "> +2 SD",
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        fill: 0,
        data: formattedDataAbove,
        borderWidth: 0,
        radius: 0,
      };
      newDataset = [...newDataset, newDataAbove];
    } else if (chartType === "MCA" || chartType === "CPR") {
      const formattedDataBelow = weeks.map((week, index) => ({
        x: week,
        y: 0.5,
      }));

      const newDataBelow = {
        label: "< -2 SD",
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        fill: 2,
        data: formattedDataBelow,
        borderWidth: 0,
        radius: 0,
      };
      newDataset = [...newDataset, newDataBelow];
    }
  }

  const data = {
    labels,
    datasets: newDataset,
  };

  const showTable = (e) => {
    setIsShowTable(true);
    setIsShowChart(false);
  };

  const showChart = (e) => {
    setIsShowChart(true);
    setIsShowTable(false);
  };

  return (
    <>
      {chart.showChart && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[1]"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="relative bg-white rounded-md shadow w-[calc(100%_-_60px)] h-[calc(100vh_-_60px)] px-5 py-5 overflow-y-auto">
            <div className="pb-0.5 w-full text-center">
              {tableHeader && (
                <p className="font-poppinsMedium text-2xl text-[#03254c]">
                  {tableHeader}
                </p>
              )}
            </div>

            <div className="absolute z-20 pr-5 pt-5 rounded-t top-0 right-0">
              <button
                type="button"
                className=""
                data-modal-hide="small-modal"
                onClick={closeModel}
              >
                <svg
                  className="w-5 h-5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            {/* Modal body */}
            <div
              className={`mt-4 gap-x-3 ${showTableButton ? "flex" : "hidden"}`}
            >
              <button
                type="button"
                className={`px-4 py-3 text-white bg-blue-500 border text-lg rounded  ${
                  isShowChart ? "bg-blue-900" : "bg-blue-500"
                }`}
                onClick={showChart}
              >
                Show Chart
              </button>

              <button
                type="button"
                className={`px-4 py-3 text-white bg-blue-500 text-lg border  rounded ${
                  isShowTable ? "bg-blue-900" : "bg-blue-500"
                }`}
                onClick={showTable}
              >
                Show Table
              </button>
            </div>
            <div
              className={`${
                showTableButton ? "hidden" : "flex"
              } laptop-small:flex-row flex-col mt-5`}
            >
              {tableData.length !== 0 && <DynamicTable data={tableData} />}
              <div className="pt-3 pl-4 laptop-big:w-3/5 laptop-small:w-1/2 w-full h-[calc(100vh_-_170px)] overflow-y-auto">
                <Line options={options} data={data} />
                <div className="ml-12 mt-5">
                  {chartData.graphReference.abstract && (
                    <p
                      className="text-sm pb-1"
                      dangerouslySetInnerHTML={{
                        __html: chartData.graphReference.abstract,
                      }}
                    ></p>
                  )}
                  {chartData.graphReference.header && (
                    <p
                      className="font-poppinsMedium text-xl pb-2"
                      dangerouslySetInnerHTML={{
                        __html: chartData.graphReference.header,
                      }}
                    ></p>
                  )}
                  {chartData.graphReference.author && (
                    <p className="text-[#03254c] font-poppinsMedium">
                      {chartData.graphReference.author}
                    </p>
                  )}
                  <div className="flex gap-x-2">
                    {chartData.graphReference.publish && (
                      <p>{chartData.graphReference.publish}</p>
                    )}
                    {chartData.graphReference.url && (
                      <a
                        className="text-blue-700"
                        href={`${chartData.graphReference.url}`}
                        target="_blank"
                      >
                        {chartData.graphReference.url}
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`${
                showTableButton ? "flex" : "hidden"
              } laptop-small:flex-row flex-col mt-5`}
            >
              {tableData.length !== 0 && isShowTable && (
                <DynamicTable data={tableData} />
              )}
              {isShowChart && (
                <div className="pt-3 pl-4 laptop-big:w-3/5 laptop-small:w-1/2 w-full h-[calc(100vh_-_170px)] overflow-y-auto">
                  <Line options={options} data={data} />
                  <div className="ml-12 mt-5">
                    {chartData.graphReference.abstract && (
                      <p
                        className="text-sm pb-1 font-poppinsBold"
                        dangerouslySetInnerHTML={{
                          __html: chartData.graphReference.abstract,
                        }}
                      ></p>
                    )}
                    {chartData.graphReference.header && (
                      <p className="font-poppinsMedium text-xl pb-2">
                        {chartData.graphReference.header}
                      </p>
                    )}
                    {chartData.graphReference.author && (
                      <p className="text-[#03254c] font-poppinsMedium">
                        {chartData.graphReference.author}
                      </p>
                    )}
                    <div className="flex gap-x-2 font-poppinsBold">
                      {chartData.graphReference.publish && (
                        <p>{chartData.graphReference.publish}</p>
                      )}
                      {chartData.graphReference.url && (
                        <a
                          className="text-blue-700 font-poppinsBold"
                          href={`${chartData.graphReference.url}`}
                          target="_blank"
                        >
                          {chartData.graphReference.url}
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default GenerateChart;
