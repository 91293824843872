import React, { useState } from 'react';
import Dialog from './Dialog';
import { Loader } from './Loader';
import axios from 'axios';

const Checkbox = ({
  hit,
  checked,
  onCheckboxChange,
  isSyndrome,
  ratio,
}) => {
  const [dialogs, setDialogs] = useState({
    details: false,
    syndrome: false,
    investigation: false
  });
  
  const [loading, setLoading] = useState(false);
  const [syndromeData, setSyndromeData] = useState(null);
  const [currentSyndrome, setCurrentSyndrome] = useState(null);
  const [error, setError] = useState(null);

  const getDialogPosition = (dialogType) => {
    const basePosition = {
      details: { x: window.innerWidth / 2 - 300, y: window.innerHeight / 2 - 300 },
      syndrome: { x: window.innerWidth / 2 - 250, y: window.innerHeight / 2 - 250 },
      investigation: { x: window.innerWidth / 2 - 200, y: window.innerHeight / 2 - 200 }
    };
    
    const openDialogsCount = Object.values(dialogs).filter(Boolean).length;
    
    return {
      x: basePosition[dialogType].x + (openDialogsCount * 40),
      y: basePosition[dialogType].y + (openDialogsCount * 40)
    };
  };

  const fetchSyndromeData = async (syndromeId) => {
    setLoading(true);
    setError(null);
    try {
      console.log('Fetching syndrome data for:', syndromeId);
      const response = await axios.get(
        `${process.env.REACT_APP_API_KEY}/syndromess/${syndromeId}?populate=abnormalitiesDetectableByUltrasound.Data,otherPossibleAssociations.Data,investigations.data`
      );
      console.log('Syndrome data response:', response.data);
      setSyndromeData(response.data.data.attributes);
    } catch (error) {
      console.error('Error fetching syndrome data:', error);
      setError('Failed to load syndrome details');
    } finally {
      setLoading(false);
    }
  };

  const openDialog = async (dialogName, syndromeInfo = null) => {
    if (isSyndrome && dialogName === 'details') {
      // If it's a syndrome and opening details, fetch the syndrome data
      await fetchSyndromeData(hit.objectID);
    } else if (dialogName === 'syndrome' && syndromeInfo) {
      // If opening a syndrome from diagnosis
      setCurrentSyndrome(syndromeInfo);
      await fetchSyndromeData(syndromeInfo.id);
    }
    
    setDialogs(prev => ({
      ...prev,
      [dialogName]: true
    }));
  };

  const closeDialog = (dialogName) => {
    setDialogs(prev => ({
      ...prev,
      [dialogName]: false
    }));
    
    if (dialogName === 'syndrome' || (isSyndrome && dialogName === 'details')) {
      setCurrentSyndrome(null);
      setSyndromeData(null);
    }
  };

  const renderSyndromeContent = () => {
    if (loading) {
      return <Loader />;
    }

    if (error) {
      return <div className="text-red-500 p-4">{error}</div>;
    }

    if (!syndromeData) {
      return <div className="p-4">No syndrome data available</div>;
    }

    return (
      <div className="space-y-6">
        {syndromeData.descriptionAndDefinition && (
          <div className="mb-6">
            <h3 className="text-xl font-semibold text-[#03254C] mb-3">Description</h3>
            <p className="text-gray-700">{syndromeData.descriptionAndDefinition}</p>
          </div>
        )}

        {syndromeData.abnormalitiesDetectableByUltrasound?.Data?.length > 0 && (
          <div className="mb-6">
            <h3 className="text-xl font-semibold text-[#03254C] mb-3">Detectable Abnormalities</h3>
            {syndromeData.abnormalitiesDetectableByUltrasound.Data.map((finding, index) => (
              <div key={index} className="mb-4">
                <h4 className="font-medium text-gray-900 mb-2">{finding.Title}</h4>
                <ul className="list-disc pl-5 space-y-1">
                  {finding.Value.split("|").map((item, idx) => (
                    <li key={idx} className="text-gray-700">
                      {item.trim().startsWith("@") ? item.trim().substring(1) : item}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        )}

        {syndromeData.investigations?.data?.length > 0 && (
          <div className="mt-4">
            <button
              onClick={() => openDialog('investigation')}
              className="px-4 py-2 text-sm font-medium text-white bg-[#03254C] rounded-md hover:bg-[#143d6b]"
            >
              View Investigations
            </button>
          </div>
        )}
      </div>
    );
  };

  const renderDetailsContent = () => {
    if (isSyndrome) {
      return renderSyndromeContent();
    }

    return (
      <>
        {hit.description && (
          <div className="mb-6">
            <h3 className="text-xl font-semibold text-[#03254C] mb-3">Description</h3>
            <p className="text-gray-700">{hit.description}</p>
          </div>
        )}

        {hit.abnormalities?.length > 0 && (
          <div className="mb-6">
            <h3 className="text-xl font-semibold text-[#03254C] mb-3">Associated Abnormalities</h3>
            {hit.abnormalities.map((abnormality, index) => (
              <div key={index} className="mb-4 last:mb-0">
                <h4 className="text-lg font-medium text-gray-900 mb-2">
                  {abnormality.name}
                </h4>
                <div className="space-y-2">
                  {abnormality.syndromes?.map((syndrome, idx) => (
                    <button
                      key={idx}
                      onClick={() => openDialog('syndrome', syndrome)}
                      className="block text-[#03254C] hover:underline text-left"
                    >
                      {syndrome.name}
                    </button>
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <div className="flex items-center justify-between p-4 border rounded-lg mb-2 hover:bg-gray-50">
        <div className="flex items-center flex-1" onClick={onCheckboxChange}>
          
          <input
            type="checkbox"
            checked={checked}
            onChange={onCheckboxChange}
            className="w-5 h-5 text-[#03254C] border-gray-300 rounded focus:ring-[#03254C]"
          />
          <span className="ml-3 text-lg font-medium text-gray-900">{hit.name}</span>
          
        </div>
        <div className="flex items-center">
          {/* Display the ratio in a box if it exists */}
          {ratio && (
            <span className="mr-6 px-4 py-1 text-s font-medium text-gray-900 bg-green-200 rounded">
              {ratio}
            </span>
          )}
          <button
            onClick={() => openDialog('details')}
            className="px-4 py-2 text-sm font-medium text-white bg-[#03254C] rounded-md hover:bg-[#143d6b]"
          >
            More Details
          </button>
        </div>
      </div>

      {/* Details Dialog */}
      <Dialog
        isOpen={dialogs.details}
        onClose={() => closeDialog('details')}
        title={`${isSyndrome ? 'Syndrome' : 'Details'}: ${hit.name}`}
        zIndex={1000}
        position={getDialogPosition('details')}
      >
        {renderDetailsContent()}
      </Dialog>

      {/* Syndrome Dialog - Only shown for diagnoses */}
      {!isSyndrome && (
        <Dialog
          isOpen={dialogs.syndrome}
          onClose={() => closeDialog('syndrome')}
          title={`Syndrome: ${currentSyndrome?.name || ''}`}
          zIndex={1001}
          position={getDialogPosition('syndrome')}
        >
          {renderSyndromeContent()}
        </Dialog>
      )}

      {/* Investigation Dialog */}
      <Dialog
        isOpen={dialogs.investigation}
        onClose={() => closeDialog('investigation')}
        title="Investigations"
        zIndex={1002}
        position={getDialogPosition('investigation')}
      >
        {syndromeData?.investigations?.data?.length > 0 ? (
          <div className="space-y-4">
            {syndromeData.investigations.data.map((item, index) => (
              <div
                key={index}
                className="p-4 border rounded-lg hover:bg-gray-50"
              >
                {item.attributes.name}
              </div>
            ))}
          </div>
        ) : (
          <p className="text-gray-700">No specific investigations available.</p>
        )}
      </Dialog>
    </>
  );
};

export default Checkbox;
