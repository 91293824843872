import * as Tables from './tablesData';

// Helper function to find closest index in an array
const findClosestWeekIndex = (weeks, targetWeek) => {
  if (!targetWeek) return -1;
  
  return weeks.reduce((closest, current, index) => {
    if (Math.abs(current - targetWeek) < Math.abs(weeks[closest] - targetWeek)) {
      return index;
    }
    return closest;
  }, 0);
};

// Get the appropriate line values based on the measurement type
const getLineValues = (tableData, weekIndex) => {
  // Different tables use different naming conventions for their lines
  const upperLine = 
    tableData.C_95th?.[weekIndex] || 
    tableData['C_97.5th']?.[weekIndex] || 
    tableData['C_Plus_2_SD']?.[weekIndex] || 
    tableData['+2 SD']?.[weekIndex];
    
  const lowerLine = 
    tableData.C_5th?.[weekIndex] || 
    tableData['C_3rd']?.[weekIndex] || 
    tableData['C_Minus_2_SD']?.[weekIndex] || 
    tableData['-2 SD']?.[weekIndex];

  return { upperLine, lowerLine };
};

// Simplified range checker that only checks if value is between lines
export const checkMeasurementRange = (measurement, value, gestationalAge) => {
  if (!value || value === 0) return 'empty';
  
  const tableData = Tables[measurement];
  if (!tableData || !tableData.weeks) return 'invalid';

  const weekIndex = findClosestWeekIndex(tableData.weeks, gestationalAge);
  if (weekIndex === -1) return 'outOfRange';

  const { upperLine, lowerLine } = getLineValues(tableData, weekIndex);
  if (!upperLine || !lowerLine) return 'invalid';

  // Simple range check: between lines = normal, outside = abnormal
  if (value >= lowerLine && value <= upperLine) {
    return 'normal';
  }
  return 'abnormal';
};

// Usage example for specific measurements
export const getRangeStatus = (measurement, value, gestationalAge) => {
  const measurementMap = {
    NT: 'NT',
    CRL: 'CRL',
    BPD: 'Fetal_Biometry_BD',
    HC: 'Fetal_Biometry_HC',
    AC: 'Fetal_Biometry_AC',
    FL: 'Fetal_Biometry_FL',
    EFW: 'Fetal_Biometry_EFW',
    TCD: 'Trans_Cerebellar_Diameter', // Updated to match your table name
    DV: 'DV',
    UA: 'UA',
    MCA: 'MCA',
    CPR: 'CPR',
    NB: 'NB',
    IT: 'IT',
    AFI: 'Oligo_AFI',
    LEFTUterine: 'LEFTUterine',
    RIGHTUterine: 'RIGHTUterine',
    Uterine: 'Uterine_Artery',
    SLVP: 'Oligo_SLVP',
  };

  const tableName = measurementMap[measurement];
  if (!tableName) return 'invalid';

  return checkMeasurementRange(tableName, value, gestationalAge);
}; 