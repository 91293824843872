import React, { useState, useEffect, useRef, Profiler } from 'react';
import TemplateService from '../components/TemplateService';

const ToolbarButton = ({ onClick, title, children }) => (
  <button
    onClick={onClick}
    className="p-2 hover:bg-gray-100 rounded"
    title={title}
  >
    {children}
  </button>
);

const getCurrentProfileId = () => {
  const profile = localStorage.getItem('profileId');
  return profile ? JSON.parse(profile) : null;
}


const SpecialElementDialog = ({ element, onSave, onClose }) => {
  const [content, setContent] = useState(element.content);

  const handleSave = () => {
    onSave(element.id, content);
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className="bg-white p-6 rounded shadow-lg w-1/2 max-w-2xl">
        <h2 className="text-xl font-bold mb-4">Edit {element.name}</h2>
        <textarea
          value={content}
          onChange={(e) => setContent(e.target.value)}
          className="w-full h-48 p-2 border rounded mb-4"
          placeholder={`Enter ${element.name} content...`}
        />
        <div className="flex justify-end space-x-2">
          <button onClick={onClose} className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600">
            Cancel
          </button>
          <button onClick={handleSave} className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

const TemplateDialog = ({ onClose }) => {


  const defaultSpecialElements = [
    { id: 'signature', name: 'Signature', content: '[Insert Signature]' },
    { id: 'name', name: 'Name', content: '[Insert Name]' },
    { id: 'distinction', name: 'Distinction', content: '[Insert Distinction]' },
    { id: 'disclaimer', name: 'Disclaimer', content: '[Insert Disclaimer]' }
  ];
  const [isLoadingTemplates, setIsLoadingTemplates] = useState(true);
  const [isLoadingElements, setIsLoadingElements] = useState(true);
  const [selectedTemplates, setSelectedTemplates] = useState(new Set());
  const [predefinedTemplates, setPredefinedTemplates] = useState([]);
  const [editorContent, setEditorContent] = useState('');
  const [showAddTemplate, setShowAddTemplate] = useState(false);
  const [newTemplate, setNewTemplate] = useState({ name: '', content: '' });
  const [customTemplates, setCustomTemplates] = useState([]);
  const [specialElements, setSpecialElements] = useState(defaultSpecialElements);
  const [isEditing, setIsEditing] = useState(false);

  const editorRef = useRef(null);
  const lastSelectionRef = useRef(null);

  const [showSpecialElementDialog, setShowSpecialElementDialog] = useState(false);
  const [currentSpecialElement, setCurrentSpecialElement] = useState(null);


  const fetchPredefinedTemplates = async () => {
    try {
      const templates = await TemplateService.getPredefinedTemplates();
      
      setPredefinedTemplates(templates);
    } catch (error) {
      console.error('Error fetching predefined templates:', error);
    }
  };

  const fetchCustomTemplates = async (profile_id) => {
    try {
      const templates = await TemplateService.getCustomTemplates(profile_id); 
      setCustomTemplates(templates);
    } catch (error) {
      console.error('Error fetching custom templates:', error);
    }
  };
  const fetchSpecialelements = async (profile_id) => {
    try {
      const templates = await TemplateService.getSpecialElements(profile_id); 
      setSpecialElements(templates);
    } catch (error) {
      console.error('Error fetching custom templates:', error);
    }
  };
  const handleAddOrUpdateTemplate = async () => {
    if (!newTemplate.name || !newTemplate.content) return;
    
    try {
      const profile_id = getCurrentProfileId();
      
      if (isEditing && newTemplate.id) {
   
        await TemplateService.updateTemplate(newTemplate.id, {
          name: newTemplate.name,
          content: newTemplate.content
        });
      } else {
        
        await TemplateService.createOrUpdate({
          name: newTemplate.name,
          content: newTemplate.content
        });
      }
      
      
      await fetchCustomTemplates(profile_id);
      
     
      setNewTemplate({ id: null, name: '', content: '' });
      setShowAddTemplate(false);
      setIsEditing(false);
    } catch (error) {
      console.error('Error saving template:', error);
    }
  };

  const handleEditTemplate = async (templateId) => {
    const template = customTemplates.find(t => t.id === templateId);
    if (template) {
      setNewTemplate({
        id: template.id,
        name: template.name,
        content: template.content
      });
      setIsEditing(true);
      setShowAddTemplate(true);
    }
  };

  const handleTemplateSelect = (templateId) => {
    const template = [...predefinedTemplates, ...customTemplates].find(t => t.id === templateId);
    const specialElement = specialElements.find(e => e.id === templateId);
    
    if (!template && !specialElement) return;

    const newSelectedTemplates = new Set(selectedTemplates);
    
    if (selectedTemplates.has(templateId)) {
      newSelectedTemplates.delete(templateId);
    } else {
      newSelectedTemplates.add(templateId);
    }
    
    setSelectedTemplates(newSelectedTemplates);

  
    const allContent = Array.from(newSelectedTemplates)
      .map(id => {
        const t = [...predefinedTemplates, ...customTemplates].find(t => t.id === id);
        const s = specialElements.find(e => e.id === id);
        return (t?.content || s?.content || '');
      })
      .filter(Boolean)
      .join('\n\n');

    editorRef.current.innerText = allContent;
    setEditorContent(allContent);
  };



  const saveSelection = () => {
    if (!editorRef.current) return;
    
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      if (editorRef.current.contains(range.commonAncestorContainer)) {
        lastSelectionRef.current = range.cloneRange();
      }
    }
  };

  const restoreSelection = () => {
    if (!editorRef.current || !lastSelectionRef.current) return;
    
    try {
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(lastSelectionRef.current.cloneRange());
    } catch (error) {
      console.warn('Failed to restore selection:', error);
    }
  };

  const insertContentAtCursor = (content) => {
    if (!editorRef.current) return;

    const plainContent = content.replace(/<[^>]*>/g, '');
    
    const currentContent = editorRef.current.innerText;
    const selection = window.getSelection();
    
    let newContent = currentContent;
    
    if (currentContent) {
      newContent += '\n\n' + plainContent;
    } else {
      newContent = plainContent;
    }
    
    editorRef.current.innerText = newContent;
    
    // Set cursor at the end
    const range = document.createRange();
    range.selectNodeContents(editorRef.current);
    range.collapse(false);
    selection.removeAllRanges();
    selection.addRange(range);
    
    saveSelection();
    setEditorContent(newContent);
  };


  const execCommand = (command, value = null) => {
    document.execCommand(command, false, value);
    editorRef.current?.focus();
    saveSelection();
  };

  const handleEditorChange = () => {
    saveSelection();
    const newContent = editorRef.current?.innerText || '';
    setEditorContent(newContent);
  };

  const handleExport = () => {
    try {
      if (!editorRef.current?.innerText) {
        console.warn('No content to export');
        return;
      }

      const plainText = editorRef.current.innerText;
      const blob = new Blob([plainText], { type: 'text/plain' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'report.doc';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Export error:', error);
    }
  };


  const handleSpecialElementSelect = (elementId) => {
    const element = specialElements.find(e => e.id === elementId);
    if (!element) return;

    if (!element.content || element.content.includes('[Insert')) {
      setCurrentSpecialElement(element);
      setShowSpecialElementDialog(true);
    } else {
      
      handleTemplateSelect(elementId);
    }
  };

  const handleEditSpecialElement = (elementId, event) => {
    event.stopPropagation();
    const element = specialElements.find(e => e.id === elementId);
    if (element) {
      setCurrentSpecialElement(element);
      setShowSpecialElementDialog(true);
    }
  };



  const handleDeleteTemplate = async (templateId) => {
    try {
      await TemplateService.deleteTemplate(templateId); 
      const profile_id = getCurrentProfileId();
      await fetchCustomTemplates(profile_id); 
    } catch (error) {
      console.error('Error deleting template:', error);
    }
  };
  const getElementName= (elementType) => {
    const names = {
      signature: 'Signature',
      name: 'Name',
      distinction: 'Distinction',
      disclaimer: 'Disclaimer'
    };
    return names[elementType] || elementType;
  };


  // const handleSpecialElementSave = async (elementId, newContent) => {

  //   console.log("newContent------------", newContent);
    

  //   try {
  //     const profileId = getCurrentProfileId();
  //     // First, fetch existing special elements to check if an entry already exists
  //     const existingElements = await TemplateService.getSpecialElements(profileId);

  //     console.log('Existing elements:', existingElements);
  //     console.log('Element ID being checked:', elementId); 
      
  //     const existingElement = existingElements.find(e => e.id === elementId);
  //     const specialID  = existingElement.specialElementId;
  //   // Log the elementId being checked
  //     console.log('Existing element:', existingElement); // Log the found element
  //     console.log('specialID:', specialID);
      
  
  //     if (existingElement) {
  //       // If element exists, update it
  //       await TemplateService.updateSpecialElement(specialID, { content: newContent });
  //     } else {
  //       // If element doesn't exist, create new one
  //       await TemplateService.createSpecialelement({ elementType: elementId, content: newContent });
  //     }
  
  //     // Fetch updated elements after save
  //     const updatedElements = await TemplateService.getSpecialElements(profileId);
  
  //     console.log('Updated elements:', updatedElements);
  
  //     // Map the updated elements to the local state format
  //     const mergedElements = specialElements.map(element => {
  //       const updatedElement = updatedElements.find(e => e.id === element.id);
  //       return {
  //         ...element,
  //         content: updatedElement ? updatedElement.content : element.content
  //       };
  //     });
  //     console.log("merged ", mergedElements);
      
  
  //     setSpecialElements(mergedElements);
  
  //     // Update editor content if the element was selected
  //     if (selectedTemplates.has(elementId)) {
  //       const newSelectedContent = Array.from(selectedTemplates)
  //         .map(id => {
  //           const element = mergedElements.find(e => e.id === id);
  //           return element?.content || '';
  //         })
  //         .filter(Boolean)
  //         .join('\n\n');
  
  //       editorRef.current.innerText = newSelectedContent;
  //       setEditorContent(newSelectedContent);
  //     }
  //   } catch (error) {
  //     console.error('Error saving special element:', error);
  //   }
  // };

  // const handleSpecialElementSave = async (elementId, newContent) => {
  //   try {
  //     // Update the special element with the new content
  //     await TemplateService.updateSpecialElement(elementId, { content: newContent });
  
  //     // Fetch the updated special elements
  //     const profileData = getCurrentProfileId();
  //     const updatedElements = await TemplateService.getSpecialElements(profileData);
  
  //     // Update the special elements state
  //     const mergedElements = defaultSpecialElements.map(defaultElement => {
  //       const customElement = updatedElements.find(e => e.id === defaultElement.id);
  //       return customElement || defaultElement;
  //     });
      
  //     setSpecialElements(mergedElements);
  //   } catch (error) {
  //     console.error('Error saving special element:', error);
  //   }
  // };

//   const handleSpecialElementSave = async (elementId, newContent) => {
//     // Save the new content for the special element
//     const response = await TemplateService.createSpecialelement({ elementType: elementId, content: newContent });

//     // Refresh the special elements list
//     const profileData = getCurrentProfileId();
//     const updatedElements = await TemplateService.getSpecialElements(profileData); // Ensure this is awaited

//     // Check if updatedElements is an array
//     if (Array.isArray(updatedElements)) {
//         const mergedElements = defaultSpecialElements.map(defaultElement => {
//             const customElement = updatedElements.find(e => e.id === defaultElement.id);
//             return customElement || defaultElement;
//         });
//         setSpecialElements(mergedElements);
//     } else {
//         console.error('Expected updatedElements to be an array, but got:', updatedElements);
//         // Handle the case where updatedElements is not an array
//     }
    
//     // Optionally, you can also update localStorage if needed
//     // localStorage.setItem('specialElements', JSON.stringify(updatedElements));
// };

// const handleSpecialElementSave = async (elementId, newContent) => {
//     try {
//       // Save the new content for the special element
//       await TemplateService.createSpecialelement({ elementType: elementId, content: newContent });

//       // Fetch updated elements
//       const profileData = getCurrentProfileId();
//       const updatedElements = await TemplateService.getSpecialElements(profileData);

//       // Update the specialElements state with the new content
//       const mergedElements = specialElements.map(element => {
//         const updatedElement = updatedElements.find(e => e.id === element.id);
//         if (updatedElement) {
//           return {
//             ...element,
//             content: updatedElement.content
//           };
//         }
//         return element;
//       });

//       setSpecialElements(mergedElements);

//       // If the element was selected, update the editor content
//       if (selectedTemplates.has(elementId)) {
//         const newSelectedContent = Array.from(selectedTemplates)
//           .map(id => {
//             const element = mergedElements.find(e => e.id === id);
//             return element?.content || '';
//           })
//           .filter(Boolean)
//           .join('\n\n');

//         editorRef.current.innerText = newSelectedContent;
//         setEditorContent(newSelectedContent);
//       }
//     } catch (error) {
//       console.error('Error saving special element:', error);
//     }
//   };

  const handleSpecialElementSave = async (elementId, newContent) => {
    try {
        const profileId = getCurrentProfileId();
        // Fetch existing special elements to check if an entry already exists
        const existingElements = await TemplateService.getSpecialElements(profileId);

        console.log('Existing elements:', existingElements);
        console.log('Element ID being checked:', elementId); 
        
        const existingElement = existingElements.find(e => e.id === elementId);
        
        if (existingElement) {
            // If element exists, update it
            await TemplateService.updateSpecialElement(existingElement.specialElementId, { content: newContent });

            // Fetch updated elements after save
            const updatedElements = await TemplateService.getSpecialElements(profileId);
            console.log('Updated elements:', updatedElements);

            // Map the updated elements to the local state format
            const mergedElements = specialElements.map(element => {
                const updatedElement = updatedElements.find(e => e.id === element.id);
                return {
                    ...element,
                    content: updatedElement ? updatedElement.content : element.content
                };
            });
            console.log("merged ", mergedElements);

            setSpecialElements(mergedElements);

            // Update editor content if the element was selected
            if (selectedTemplates.has(elementId)) {
                const newSelectedContent = Array.from(selectedTemplates)
                    .map(id => {
                        const element = mergedElements.find(e => e.id === id);
                        return element?.content || '';
                    })
                    .filter(Boolean)
                    .join('\n\n');

                editorRef.current.innerText = newSelectedContent;
                setEditorContent(newSelectedContent);
            }
        } else {
            // Save the new content for the special element
      await TemplateService.createSpecialelement({ elementType: elementId, content: newContent });

      // Fetch updated elements
      const profileData = getCurrentProfileId();
      const updatedElements = await TemplateService.getSpecialElements(profileData);

      // Update the specialElements state with the new content
      const mergedElements = specialElements.map(element => {
        const updatedElement = updatedElements.find(e => e.id === element.id);
        if (updatedElement) {
          return {
            ...element,
            content: updatedElement.content
          };
        }
        return element;
      });

      setSpecialElements(mergedElements);

      // If the element was selected, update the editor content
      if (selectedTemplates.has(elementId)) {
        const newSelectedContent = Array.from(selectedTemplates)
          .map(id => {
            const element = mergedElements.find(e => e.id === id);
            return element?.content || '';
          })
          .filter(Boolean)
          .join('\n\n');

        editorRef.current.innerText = newSelectedContent;
        setEditorContent(newSelectedContent);
      }
        }
    } catch (error) {
        console.error('Error saving special element:', error);
    }
};

  const handleReset = () => {
  
    editorRef.current.innerText = '';
    setEditorContent('');
    
  
    setSelectedTemplates(new Set());
  };

  useEffect(() => {
    const editor = editorRef.current;
    if (editor) {
      editor.addEventListener('mouseup', saveSelection);
      editor.addEventListener('keyup', saveSelection);
      editor.addEventListener('focus', restoreSelection);
    }

    return () => {
      if (editor) {
        editor.removeEventListener('mouseup', saveSelection);
        editor.removeEventListener('keyup', saveSelection);
        editor.removeEventListener('focus', restoreSelection);
      }
    };
  }, []);

 
  useEffect(() => {
    const fetchData = async () => {
      try {
        
        setIsLoadingTemplates(true);
        const getCurrentProfileId = () => {
          const profile = localStorage.getItem('profileId');
          return profile ? JSON.parse(profile) : null;
        }
        const profileData = getCurrentProfileId();
        console.log('Current profile ID................:', profileData);
        const profile_id = getCurrentProfileId();
        await fetchCustomTemplates(profile_id);
        setIsLoadingTemplates(false); 
        setIsLoadingElements(true);
        
        const elements = await TemplateService.getSpecialElements(profile_id);
       
        const mergedElements = defaultSpecialElements.map(defaultElement => {
          const customElement = elements.find(e => e.id === defaultElement.id);
          return customElement || defaultElement;
        });
        setSpecialElements(mergedElements);
        setIsLoadingElements(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoadingTemplates(false);
        setIsLoadingElements(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const profile_id = getCurrentProfileId();
    fetchCustomTemplates(profile_id);
    fetchPredefinedTemplates(); 
  }, []); 

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className="bg-white w-full h-full max-h-screen flex">
        {/* Templates sidebar */}
        <div className="w-1/4 border-r border-gray-200 p-4 overflow-y-auto">
          <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">Templates</h2>
            <button
              onClick={() => {
                if (showAddTemplate && isEditing) {
                  setNewTemplate({ id: null, name: '', content: '' });
                  setIsEditing(false);
                }
                setShowAddTemplate(!showAddTemplate);
              }}
              className="px-3 py-1 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              {showAddTemplate ? 'Cancel' : 'Add Template'}
            </button>
          </div>

          {/* Add/Edit Template Form */}
          {showAddTemplate && (
            <div className="mb-4 p-3 border rounded bg-gray-50">
              <input
                type="text"
                placeholder="Template Name"
                value={newTemplate.name}
                onChange={(e) => setNewTemplate({...newTemplate, name: e.target.value})}
                className="w-full mb-2 p-2 border rounded"
              />
              <textarea
                placeholder="Template Content"
                value={newTemplate.content}
                onChange={(e) => setNewTemplate({...newTemplate, content: e.target.value})}
                className="w-full mb-2 p-2 border rounded h-32"
              />
              <button
                onClick={handleAddOrUpdateTemplate}
                disabled={!newTemplate.name || !newTemplate.content}
                className="w-full px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 disabled:opacity-50"
              >
                {isEditing ? 'Update Template' : 'Save Template'}
              </button>
            </div>
          )}

          {/* Predefined Templates Section */}
          <div className="mb-4">
            <div className="space-y-2">
              {predefinedTemplates.map((template) => (
                <div
                  key={template.id}
                  className="flex items-center p-3 rounded hover:bg-gray-100 cursor-pointer"
                  onClick={() => handleTemplateSelect(template.id)}
                >
                  <input
                    type="checkbox"
                    checked={selectedTemplates.has(template.id)}
                    onChange={() => {}}
                    className="mr-2"
                  />
                  <span>{template.name}</span>
                </div>
              ))}
            </div>
          </div>

          {/* Custom Templates Section */}
          {/* <h2 className="text-xl font-bold">Custom Templates</h2> */}

           {/* Custom Templates Section */}
           {customTemplates.length > 0 && (
            <div className="mb-4">
              <div className="space-y-2">
                {customTemplates.map((template) => (
                  <div
                    key={template.id}
                    className="flex items-center justify-between p-3 rounded hover:bg-gray-100 cursor-pointer"
                    onClick={() => handleTemplateSelect(template.id)}
                  >
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        checked={selectedTemplates.has(template.id)}
                        onChange={() => {}}
                        className="mr-2"
                      />
                      <span className="mr-4">{template.name}</span>
                      {/* <span className="text-gray-500">{template.content}</span> */}
                    </div>
                    <div className="flex space-x-2">
                      <button
                        onClick={() => handleEditTemplate(template.id)}
                        className="px-2 py-1 text-sm bg-yellow-200 rounded hover:bg-yellow-300"
                        title="Edit Template"
                      >
                        Edit
                      </button>
                      <button
                        onClick={() => handleDeleteTemplate(template.id)}
                        className="px-2 py-1 text-sm bg-red-200 rounded hover:bg-red-300"
                        title="Delete Template"
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
         
          <div className="mt-auto">
            <div className="space-y-2">
              {specialElements.map((element) => (
                <div
                  key={element.id}
                  className="flex items-center justify-between p-3 rounded hover:bg-gray-100 cursor-pointer"
                  onClick={() => handleSpecialElementSelect(element.id)}
                >
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      checked={selectedTemplates.has(element.id)}
                      onChange={() => {}}
                      className="mr-2"
                    />
                    <span>{element.name}</span>
                  </div>
                  <button
                    onClick={(e) => handleEditSpecialElement(element.id, e)}
                    className="px-2 py-1 text-sm bg-gray-200 rounded hover:bg-gray-300"
                    title="Change content"
                  >
                    Change Content
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Editor area */}
        <div className="flex-1 p-4 flex flex-col h-full">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-bold">Report Content</h2>
            <div className="space-x-2">
              <button
                onClick={handleExport}
                disabled={!editorContent}
                className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 disabled:opacity-50"
              >
                Export as Word
              </button>
              <button
                onClick={handleReset}
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
              >
                Reset
              </button>
              <button
                onClick={onClose}
                className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
              >
                Close
              </button>
            </div>
          </div>

          <div className="border border-gray-200 rounded-lg mb-4 p-2 bg-white">
            <div className="flex items-center space-x-2 border-b border-gray-200 pb-2">
              <ToolbarButton onClick={() => execCommand('formatBlock', 'h2')} title="Heading">
                <span className="font-bold text-lg">H</span>
              </ToolbarButton>
              <ToolbarButton onClick={() => execCommand('bold')} title="Bold">
                <span className="font-bold">B</span>
              </ToolbarButton>
              <ToolbarButton onClick={() => execCommand('italic')} title="Italic">
                <span className="italic">I</span>
              </ToolbarButton>
              <ToolbarButton onClick={() => execCommand('insertUnorderedList')} title="Bullet List">
                <span>•</span>
              </ToolbarButton>
              <ToolbarButton onClick={() => execCommand('justifyLeft')} title="Align Left">
                ⇤
              </ToolbarButton>
              <ToolbarButton onClick={() => execCommand('justifyCenter')} title="Align Center">
                ⇔
              </ToolbarButton>
              <ToolbarButton onClick={() => execCommand('justifyRight')} title="Align Right">
                ⇥
              </ToolbarButton>

              {/* Divider */}
              

              
            </div>
          </div>

          <div
            ref={editorRef}
            className="flex-1 border border-gray-300 p-4 overflow-y-auto bg-white rounded-lg whitespace-pre-wrap"
            contentEditable={true}
            onInput={handleEditorChange}
            style={{ maxHeight: 'calc(100vh - 280px)' }}
          />
        </div>
      </div>

      {showSpecialElementDialog && currentSpecialElement && (
        <SpecialElementDialog
          element={currentSpecialElement}
          onSave={handleSpecialElementSave}
          onClose={() => setShowSpecialElementDialog(false)}
        />
      )}
    </div>
  );
};

export default TemplateDialog;