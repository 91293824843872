// components/Dialog.js
import React from 'react';
import ReactDOM from 'react-dom';
import Draggable from 'react-draggable';

const Dialog = ({ isOpen, onClose, title, children, zIndex = 1000, position }) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="fixed inset-0 pointer-events-none" style={{ zIndex: zIndex - 1 }}>
      <div className="fixed inset-0 bg-black bg-opacity-25 pointer-events-auto" />
      <Draggable handle=".dialog-handle" defaultPosition={position}>
        <div 
          className="fixed pointer-events-auto bg-white rounded-lg shadow-xl"
          style={{ 
            zIndex,
            width: '600px',
            maxHeight: '80vh',
            // position: 'fixed',
            top:"-20%",
            left:"-10%"
          }}
        >
          <div className="dialog-handle cursor-move bg-[#03254C] text-white p-4 flex justify-between items-center rounded-t-lg">
            <h2 className="text-lg font-semibold">{title}</h2>
            <button 
              onClick={onClose}
              className="p-1 hover:bg-[#143d6b] rounded transition-colors"
            >
              <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                <path d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" />
              </svg>
            </button>
          </div>
          <div className="p-6 overflow-y-auto" style={{ maxHeight: 'calc(80vh - 60px)' }}>
            {children}
          </div>
        </div>
      </Draggable>
    </div>,
    document.body
  );
};

export default Dialog;