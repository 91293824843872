import React, { useEffect, useState, useCallback } from "react";
import  { jsPDF } from "jspdf";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import {
  Fetal_Biometry_AC,
  Fetal_Biometry_BD,
  Fetal_Biometry_EFW,
  Fetal_Biometry_FL,
  Fetal_Biometry_HC,
  Trans_Cerebellar_Diameter,
  DV,
  Uterine_Artery,
  Uterine_Artery_IP,
  Oligo_SLVP,
  Oligo_AFI,
  UA,
  MCA,
  CPR,
  NB,
  IT,
  NT,
  CRL,
} from "../utils/tablesData";
import "./search.css";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function GenerateAllChart({ chart, setChart, onClose ,name}) {
  const [chartData, setChartData] = useState([]);
  const [isInitialized, setIsInitialized] = useState(false);
  const [isPdfGenerating, setIsPdfGenerating] = useState(false);

  const closeModal = useCallback(() => {
    setChart(prev => ({ ...prev, showChart: false }));
    if (onClose) onClose();
  }, [setChart, onClose]);

  // Handle escape key
  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === "Escape") {
        closeModal();
      }
    };

    window.addEventListener("keydown", handleEscape);
    return () => window.removeEventListener("keydown", handleEscape);
  }, [closeModal]);

  // Generate chart data only once when component mounts
  useEffect(() => {
    if (!isInitialized && chart.value && Object.keys(chart.value).length > 0) {
      const newChartData = [];
      const keys = Object.keys(chart.value);
      keys.forEach(key => {
        if (chart.value[key]) {
          const chartConfig = getChartConfig(key, chart.value[key], chart.week);
          if (chartConfig) {
            const processedData = graphObjectSet(chartConfig);
            if (processedData) {
              newChartData.push(processedData);
            }
          }
        }
      });

      setChartData(newChartData);
      setIsInitialized(true);
    }
  }, [chart.value, chart.week, isInitialized]);

  const getChartConfig = (key, value, week) => {
    const configs = {
      BPD: { obj: Fetal_Biometry_BD, name: 'BPD' },
      HC: { obj: Fetal_Biometry_HC, name: 'HC' },
      AC: { obj: Fetal_Biometry_AC, name: 'AC' },
      FL: { obj: Fetal_Biometry_FL, name: 'FL' },
      EFW: { obj: Fetal_Biometry_EFW, name: 'EFW' },
      TCD: { obj: Trans_Cerebellar_Diameter, name: 'TCD' },
      DV: { obj: DV, name: 'DV' },
      Uterine: { obj: Uterine_Artery, name: 'Uterine Artery' },  
      SLVP: { obj: Oligo_SLVP, name: 'SLVP' },
      AFI: { obj: Oligo_AFI, name: 'AFI' },
      UA: { obj: UA, name: 'UA' },
      MCA: { obj: MCA, name: 'MCA' },
      CPR: { obj: CPR, name: 'CPR' },
      NB: { obj: NB, name: 'NB' },
      IT: { obj: IT, name: 'IT' },
      NT: { obj: NT, name: 'NT' },
      CRL: { obj: CRL, name: 'CRL' }
    };

    if (configs[key]) {
      return {
        name: configs[key].name, 
        pointValue: value,
        labels: configs[key].obj.weeks,
        data: Object.keys(configs[key].obj),
        obj: configs[key].obj,
        week
      };
    }
    return null;
  };

  const graphObjectSet = (data) => {
    const borderColors = [
      "rgba(255, 0, 0, 1)",    // Red for upper line
      "rgba(0, 128, 0, 1)",    // Green for middle line
      "rgba(255, 0, 0, 1)",    // Red for lower line
    ];
    
    const backgroundColor = [
      "rgba(255, 0, 0, 0.2)",  // Red with transparency
      "rgba(0, 128, 0, 0.2)",  // Green with transparency
      "rgba(255, 0, 0, 0.2)",  // Red with transparency
    ];

    let datasets = data.data
      .filter(label => label !== "weeks")
      .map((label, index) => ({
        label,
        data: data.obj[label],
        borderColor: borderColors[index % borderColors.length],
        backgroundColor: backgroundColor[index % backgroundColor.length],
        borderWidth: 2,  // Optional: make lines more visible
        fill: false,     // Prevents area filling
      }));

    const dynamicPointIndex = data.labels.indexOf(data.week);
    const dynamicPointDataset = {
      label: data.name,
      backgroundColor: "red",
      borderColor: "red",
      data: Array(data.labels.length)
        .fill(null)
        .map((_, index) => index === dynamicPointIndex ? data.pointValue : null),
      pointBackgroundColor: "red",
      pointRadius: 5,
      pointHoverRadius: 10,
      showLine: true,
    };

    return {
      labels: data.labels,
      datasets: [...datasets, dynamicPointDataset]
    };
  };


  const generatePDF = async () => {
    setIsPdfGenerating(true);
    const doc = new jsPDF('p', 'mm', 'a4');
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const margin = 10;
    const imageWidth = pageWidth - (2 * margin);
    const imageHeight = (imageWidth * 0.6); // Maintain aspect ratio
  
    try {
      let currentY = margin; // Track the Y-coordinate for placing charts on the page
  
      for (let i = 0; i < chartData.length; i++) {
        const canvas = document.getElementById(`chart-${i}`);
        if (canvas) {
          // Add chart title
          const title = chartData[i].datasets[3]?.label || `Chart ${i + 1}`;
          doc.setFontSize(16);
          doc.text(title, pageWidth / 2, currentY + 5, { align: 'center' });
  
          // Convert chart to image and add to PDF
          const imgData = canvas.toDataURL('image/png', 1.0);
          doc.addImage(
            imgData,
            'PNG',
            margin,
            currentY + 10,
            imageWidth,
            imageHeight
          );
  
          // Adjust the Y-coordinate for the next chart
          currentY += imageHeight + 30; // Add space between charts
  
          // Check if the next chart fits on the current page; if not, add a new page
          if (currentY + imageHeight > pageHeight - margin) {
            doc.addPage();
            currentY = margin; // Reset Y-coordinate for the new page
          }
        }
      }
  
      // Save the PDF
      doc.save(`${name}_charts.pdf`);
    } catch (error) {
      console.error('Error generating PDF:', error);
    } finally {
      setIsPdfGenerating(false);
    }
  };

  if (!chart.showChart) return null;

  return (
    <div 
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[1]"
      onClick={(e) => e.target === e.currentTarget && closeModal()}
    >
      <div className="relative bg-white rounded-md shadow w-[calc(100%_-_60px)] h-[calc(100vh_-_60px)] px-5 py-5">
        <button
          type="button"
          className="absolute z-20 right-5 top-5"
          onClick={closeModal}
        >
          <svg
            className="w-5 h-5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 14"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
            />
          </svg>
          <span className="sr-only">Close modal</span>
        </button>

        <div className="flex flex-col h-full">
          <div className="overflow-y-auto flex-grow">
            <div className="flex flex-wrap mt-10">
              {chartData.map((data, index) => (
                <div
                  key={index}
                  className={`p-2 ${
                    chartData.length === 1 ? "w-full" : "laptop-small:w-1/2 w-full"
                  }`}
                >
                  <Line
                    id={`chart-${index}`}
                    className="shadow-custom-3"
                    options={{
                      responsive: true,
                      plugins: {
                        legend: {
                          position: "top",
                        },
                        title: {
                          display: true,
                          text: data.datasets[3]?.label || '',
                        },
                      },
                      scales: {
                        x: {
                          display: true,
                          title: {
                            display: true,
                            text: "weeks",
                            color: "blue",
                          },
                        },
                        y: {
                          display: true,
                          title: {
                            display: true,
                            text: "mm",
                            color: "blue",
                          },
                        },
                      },
                    }}
                    data={data}
                  />
                </div>
              ))}
            </div>
          </div>
          
          <div className="flex justify-center mt-6 mb-4">
            <button
              type="button"
              className="bg-blue-500 hover:bg-blue-600 text-white px-6 py-3 rounded-lg font-semibold transition-colors duration-200 flex items-center gap-2 disabled:opacity-50 disabled:cursor-not-allowed"
              onClick={generatePDF}
              disabled={isPdfGenerating}
            >
              {isPdfGenerating ? (
                <>
                  <svg className="animate-spin h-5 w-5 mr-2" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none" />
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                  </svg>
                  Generating PDF...
                </>
              ) : (
                <>
                  <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                  </svg>
                  Print Charts
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GenerateAllChart;