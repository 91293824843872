import axios from "axios";

// const STRAPI_URL = "https://uat-api.scanofe.com/api";
const STRAPI_URL = process.env.REACT_APP_API_KEY;

class StrapiService {
  // Get current user's profile ID
  static getCurrentProfileId() {
    return localStorage.getItem('profileId');
  }

  async createOrUpdatePatient(patientData) {
    try {
      const profileId = StrapiService.getCurrentProfileId();
      
      if (!profileId) {
        throw new Error('No profile ID found');
      }

      if (!patientData) {
        throw new Error('No patient data provided');
      }

      const result = await this.createPatient(patientData, profileId);
      return result;
    } catch (error) {
      console.error('Error in createOrUpdatePatient:', error.response?.data || error);
      throw error;
    }
  }

  async createPatient(rawData, profileId) {
    try {
      const patientName = rawData.PatientName || 'Unknown Patient';
      const measurement = {
        BPD: this.extractData(rawData.BPD || rawData['Biparietal Diameter']),
        HC: this.extractData(rawData.HC || rawData['Head Circumference']),
        AC: this.extractData(rawData.AC || rawData['Abdominal Circumference']),
        FL: this.extractData(rawData.FL || rawData['Femur Length']),
        EFW: this.extractData(rawData.EFW || rawData['Estimated Weight']),
        TCD: this.extractData(rawData.TCD || rawData['Trans Cerebellar Diameter']),
        DV: this.extractData(rawData.DV || rawData['Ductus Venosus']),
        MCA: this.extractData(rawData.MCA || rawData['Middle Cerebral Artery']),
        UA: this.extractData(rawData.UA || rawData['Pulsatility Index']),
        CPR: this.extractData(rawData.MCA || rawData['Middle Cerebral Artery']) && this.extractData(rawData.UA || rawData['Pulsatility Index']) 
        ? parseFloat((this.extractData(rawData['Middle Cerebral Artery']) / this.extractData(rawData['Pulsatility Index'])).toFixed(2)) 
        : '',
        AFI: this.extractData(rawData.AFI || rawData['Amniotic Fluid Index']),
        SLVP: this.extractData(rawData.SLVP || rawData['Single Largest Vertical Pocket']),
        Uterine: this.extractData(rawData.Uterine || rawData['Uterine']),
        NB: this.extractData(rawData.NB || rawData['nasal bone length']),
        NT: this.extractData(rawData.NT || rawData['Nuchal Translucency']),
        IT: this.extractData(rawData.IT || rawData['Intercranial Translucency']),
        CRL: this.extractData(rawData.CRL || rawData['Crown Rump Length']),
        LEFTUterine: this.extractData(rawData.LEFTUterine || rawData['Left Uterine Pulsatility Index']),
        RIGHTUterine: this.extractData(rawData.RIGHTUterine || rawData['Right Uterine Pulsatility Index'])
      };

      const hasValues = Object.values(measurement).some(value => 
        value !== '' && value !== null && value !== undefined && value !== 'date'
      );

      if (!hasValues) {
        // console.log('No valid measurements to save');
        return null;
      }

      const patientData = {
        data: {
          Name: patientName,
          PatientID: rawData.PatientID,
          DateOfBirth: this.formatDate(rawData.PatientBirthDate) || new Date().toISOString().split('T')[0],
          LMPdate: this.formatDate(rawData.LMP),
          Measurements: [measurement],
          timestamp: new Date().toISOString(),
          scanofe_user: profileId // Associate with user's profile ID
        }
      };

      // console.log('Sending create request with:', patientData);

      try {
        const response = await axios.post(`${STRAPI_URL}/patients-data`, patientData);
        // console.log('Create response:', response.data);
        return response.data;
      } catch (error) {
        console.error('API Error:', error.response?.data || error);
        throw error;
      }
    } catch (error) {
      console.error('Error creating patient:', error.response?.data || error);
      throw error;
    }
  }

  async getAllPatients() {
    try {
      const profileId = StrapiService.getCurrentProfileId();
      
      if (!profileId) {
        throw new Error('No profile ID found');
      }

      const response = await axios.get(`${STRAPI_URL}/patients-data`, {
        params: {
          'filters[scanofe_user][id][$eq]': profileId,
          'sort[0]': 'createdAt:desc', 
          populate: '*'
        }
      });

      return response.data;
    } catch (error) {
      console.error('Error getting patients:', error.response?.data || error);
      throw error;
    }
  }

  extractData(value) {
    if (!value) return '';
    const numValue = parseFloat(value);
    return isNaN(numValue) ? value.toString().trim() : numValue;
  }

  formatDate(dateString) {
    if (!dateString || !/^\d{8}$/.test(dateString)) return '';
    const year = dateString.slice(0, 4);
    const month = dateString.slice(4, 6);
    const day = dateString.slice(6, 8);
    return `${year}-${month}-${day}`;
  }

  static async getPatientByData(data) {
    try {
      const response = await axios.get(`${process.env.REACT_APP_STRAPI_URL}/api/patients-data`, {
        params: {
          filters: {
            Name: data.name,
            DateOfBirth: data.dateOfBirth,
          }
        }
      });
      return response.data.data.length > 0 ? response.data.data[0] : null;
    } catch (error) {
      console.error('Error checking existing patient:', error);
      return null;
    }
  }

  async getLastPatientData() {
    try {
      const profileId = StrapiService.getCurrentProfileId();
      
      if (!profileId) {
        throw new Error('No profile ID found');
      }

      const response = await axios.get(`${STRAPI_URL}/patients-data`, {
        params: {
          'filters[scanofe_user][id][$eq]': profileId,
          sort: 'createdAt:desc',
          pagination: {
            limit: 1
          },
          populate: '*'
        }
      });
      
      return response.data.data[0];
    } catch (error) {
      console.error('Error fetching last patient data:', error);
      return null;
    }
  }

  async getLastTwoPatients() {
    try {
      const response = await axios.get(`${STRAPI_URL}/patients-data`, {
        params: {
          sort: 'createdAt:desc',
          pagination: {
            limit: 2
          }
        }
      });
      
      return response.data.data;
    } catch (error) {
      console.error('Error fetching last two patients:', error);
      return [];
    }
  }
}

export default new StrapiService();
