// Risk values lookup table
const RISK_TABLE = {
    21: 1078, 22: 1056, 23: 1035, 24: 1014, 25: 994,
    26: 914, 27: 841, 28: 774, 29: 712, 30: 655,
    31: 550, 32: 462, 33: 388, 34: 326, 35: 273,
    36: 206, 37: 158, 38: 120, 39: 91, 40: 69
};

function calculateAge(dob) {
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    console.log('Calculated age:', age, 'from DOB:', dob);
    return age;
}

function getRiskValue(age) {
    // Clamp age between 21 and 40
    const clampedAge = Math.min(Math.max(age, 21), 40);
    const risk = RISK_TABLE[clampedAge];
    console.log('Base risk for age', clampedAge, ':', risk);
    return risk;
}

function calculateTrisomyRisk(dob, LR) {
    console.log('Calculating risk with DOB:', dob, 'and LR:', LR);
    const age = calculateAge(dob);
    const baseRisk = getRiskValue(age);
    const risk = baseRisk / LR;
    
    const roundedRisk = Math.round(risk);
    console.log('Final calculated risk: 1 in', roundedRisk);
    return roundedRisk;
}

// Export the function if using modules
export { calculateTrisomyRisk };