import React from "react";
// import { HashRouter, Routes as Routing, Route } from "react-router-dom";
import { BrowserRouter , Routes as Routing, Route } from "react-router-dom";
import Form from "./pages/Form";
import Login from "./pages/Login";
import Welcome from "./pages/Welcome";
import Profile from "./pages/Profile";
import ProtectedRoutes from "./routes/ProtectedRoutes";
import { useAppState } from "./Context";
import { Navigate } from "react-router-dom";
import PatientList from "./components/PatientList";


function Routes() {
  const { getIsAuthenticated } = useAppState("auth");

  return (
    // <HashRouter>
    <BrowserRouter>
      <Routing>
        {/* <Route path="/" element={<Login />} /> */}
        <Route
          path="/"
          element={
            getIsAuthenticated() ? (
              <Navigate to="/welcome" />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route path="/login" element={<Login />} />

        {/* Protected routes */}
        <Route exact element={<ProtectedRoutes />}>
          <Route path="/examination" element={<Form />} />
          {/* <Route path="/patients" element={<PatientList />} /> */}
          <Route path="/welcome" element={<Welcome />} />
          <Route path="/profile" element={<Profile />} />
          <Route 
        path="/patients" 
        element={
          <Form>
            {(handlePatientSelection) => (
              <PatientList onSelectPatient={handlePatientSelection} />
            )}
          </Form>
        } 
      />
        </Route>
      </Routing>
    {/* </HashRouter> */}
    </BrowserRouter>
  );
}

export default Routes;
