import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FiSearch, FiCalendar, FiUser, FiClock, FiFilter } from 'react-icons/fi';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import StrapiService from './StrapiService';
import Navbar from '../components/Navbar';
import ScanofeLogo from "../assets/ScanOFeMainLogo.png";
import useMainState from '../Context/state/useMainState';
import Pagination from './Pagination'; // Import the Pagination component

const PatientList = ({ onSelectPatient }) => {
  const navigate = useNavigate();
  const [patients, setPatients] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [patientsPerPage, setPatientsPerPage] = useState(10);
  const [filterDate, setFilterDate] = useState(null);
  const [showFilters, setShowFilters] = useState(false);
  const [sortBy, setSortBy] = useState('createdAt');
  const [sortOrder, setSortOrder] = useState('desc');

  const {
    isDropdownOpen,
    setIsDropdownOpen,
    dropDownRef,
  } = useMainState("app");

  const handleClick = (event) => {
    if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  const formatDate = (dateString, includeTime = false) => {
    if (!dateString) return '-';
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    
    if (includeTime) {
      let hours = date.getHours();
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const seconds = date.getSeconds().toString().padStart(2, '0');
      const ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      return `${day}-${month}-${year}, ${hours}:${minutes}:${seconds} ${ampm}`;
    }
    
    return `${day}-${month}-${year}`;
  };

  useEffect(() => {
    fetchPatients();
  }, []);

  const fetchPatients = async () => {
    try {
      setLoading(true);
      const response = await StrapiService.getAllPatients();
      const sortedPatients = response.data.sort((a, b) => {
        return new Date(b.attributes.createdAt) - new Date(a.attributes.createdAt);
      });
      setPatients(sortedPatients || []);
    } catch (error) {
      console.error('Error fetching patients:', error);
    } finally {
      setLoading(false);
    }
  };

  const filterPatients = () => {
    return patients.filter(patient => {
      const matchesName = patient.attributes.Name.toLowerCase().includes(searchTerm.toLowerCase());
      const createdDate = new Date(patient.attributes.createdAt);
      
      if (filterDate) {
        const matches = createdDate.getMonth() === filterDate.getMonth() &&
                       createdDate.getFullYear() === filterDate.getFullYear();
        return matchesName && matches;
      }
      
      return matchesName;
    });
  };

  const sortPatients = (filteredPatients) => {
    return filteredPatients.sort((a, b) => {
      const aValue = a.attributes[sortBy];
      const bValue = b.attributes[sortBy];
      
      if (sortOrder === 'desc') {
        return new Date(bValue) - new Date(aValue);
      }
      return new Date(aValue) - new Date(bValue);
    });
  };

  const filteredPatients = filterPatients();
  const sortedPatients = sortPatients(filteredPatients);

  const indexOfLastPatient = currentPage * patientsPerPage;
  const indexOfFirstPatient = indexOfLastPatient - patientsPerPage;
  const currentPatients = sortedPatients.slice(indexOfFirstPatient, indexOfLastPatient);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handlePatientSelect = (patient) => {
    if (onSelectPatient) {
      onSelectPatient(patient);
      navigate('/examination');
    }
  };

  const handleEntriesPerPageChange = (newValue) => {
    setPatientsPerPage(newValue);
    setCurrentPage(1);
  };

  const totalPages = Math.ceil(filteredPatients.length / patientsPerPage);

  return (
    <>
      <Navbar
        isDropdownOpen={isDropdownOpen}
        setIsDropdownOpen={setIsDropdownOpen}
        dropDownRef={dropDownRef}
      />
      <div className="min-h-screen bg-gray-50 py-8 px-4 sm:px-6 lg:px-8 shadow-custom-2">
        <div className="max-w-7xl mx-auto shadow-custom-3 rounded-md">
          {/* Header Section */}
          <div className="bg-[#E5EEF7] rounded-t-lg p-4 flex items-center justify-between relative shadow-custom-2">
            <div className="absolute left-4">
              <img 
                src={ScanofeLogo} 
                alt="ScanOFe Logo" 
                className="h-8 w-auto"
              />
            </div>
            
            <div className="flex-1 text-center">
              <h1 className="text-[#03254c] text-xl font-semibold">
                Patient Records
              </h1>
            </div>

            <button
              onClick={() => navigate(-1)}
              className="absolute right-4 p-2 hover:bg-[#d4e3f3] rounded-full transition-colors duration-200"
              title="Close"
            >
              <svg
                className="w-5 h-5 text-[#03254c]"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          
          {/* Search and Filter Bar */}
          <div className="flex flex-col md:flex-row mt-2 gap-4 items-center">
            <div className="relative flex-1">
              <FiSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              <input
                type="text"
                placeholder="Search by patient name..."
                className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#03254c] focus:border-transparent"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            
            <button
              onClick={() => setShowFilters(!showFilters)}
              className="flex items-center mr-4 gap-2 px-4 py-2 bg-gray-100 hover:bg-gray-200 rounded-lg transition-colors"
            >
              <FiFilter />
              Filters
            </button>
          </div>

          {/* Advanced Filters */}
          {showFilters && (
            <div className="mt-4 p-4 bg-gray-50 rounded-lg">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">Filter by Month</label>
                  <DatePicker
                    selected={filterDate}
                    onChange={date => setFilterDate(date)}
                    dateFormat="MM/yyyy"
                    showMonthYearPicker
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#03254c]"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">Sort By</label>
                  <select
                    value={sortBy}
                    onChange={(e) => setSortBy(e.target.value)}
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#03254c]"
                  >
                    <option value="createdAt">Created Date</option>
                    <option value="Name">Patient Name</option>
                    <option value="DateOfBirth">Date of Birth</option>
                  </select>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">Order</label>
                  <select
                    value={sortOrder}
                    onChange={(e) => setSortOrder(e.target.value)}
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#03254c]"
                  >
                    <option value="desc">Newest First</option>
                    <option value="asc">Oldest First</option>
                  </select>
                </div>
              </div>
            </div>
          )}

          {/* Patient List Table */}
          {loading ? (
            <div className="flex items-center justify-center h-64">
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-[#03254c]"></div>
            </div>
          ) : (
            <div className="bg-white rounded-lg shadow-sm overflow-hidden">
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-6 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        <div className="flex items-center gap-2">
                          <FiUser className="text-gray-400" />
                          Name
                        </div>
                      </th>
                      <th className="px-6 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        <div className="flex items-center gap-2">
                          <FiCalendar className="text-gray-400" />
                          Date of Birth
                        </div>
                      </th>
                      <th className="px-6 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        <div className="flex items-center gap-2">
                          <FiCalendar className="text-gray-400" />
                          LMP Date
                        </div>
                      </th>
                      <th className="px-6 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        <div className="flex items-center gap-2">
                          <FiClock className="text-gray-400" />
                          Created At
                        </div>
                      </th>
                      <th className="px-6 py-4 text-right text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {currentPatients.length === 0 ? (
                      <tr>
                        <td colSpan="5" className="text-center py-4 text-gray-500">
                          No records for patients
                        </td>
                      </tr>
                    ) : (currentPatients.map((patient) => (
                      <tr key={patient.id} className="hover:bg-gray-50 transition-colors">
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="font-medium text-gray-900">{patient.attributes.Name}</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-gray-600">
                          {formatDate(patient.attributes.DateOfBirth)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-gray-600">
                          {formatDate(patient.attributes.LMPdate)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-gray-600">
                          {formatDate(patient.attributes.createdAt, true)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right">
                          <button
                            onClick={() => handlePatientSelect(patient)}
                            className="inline-flex items-center px-4 py-2 bg-[#03254c] text-white text-sm font-medium rounded-md hover:bg-[#043669] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#03254c] transition-colors"
                          >
                            View Details
                          </button>
                        </td>
                      </tr>
                    )))}
                  </tbody>
                </table>
              </div>

              {/* Show pagination only if there are multiple entries */}
              {filteredPatients.length > 1 && (
                <Pagination 
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={setCurrentPage}
                  entriesPerPage={patientsPerPage}
                  onEntriesPerPageChange={handleEntriesPerPageChange}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PatientList;