export const Fetal_Biometry_BD = {
  weeks: [
    12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
    31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42,
  ],
  C_5th: [
    16.9, 20.2, 23.5, 26.6, 30, 33.2, 36.6, 39.9, 43.2, 46.6, 49.9, 53, 56,
    59.1, 61.9, 64.7, 67.5, 70.1, 72.3, 74.8, 76.8, 78.6, 80.2, 81.6, 82.8, 84,
    85.1, 86.2, 87.2, 88.5, 89.2,
  ],
  C_50th: [
    19.3, 22.8, 26.1, 29.4, 32.9, 36.3, 39.7, 43.1, 46.4, 49.8, 53.1, 56.3,
    59.3, 62.5, 65.4, 68.4, 71.3, 73.9, 76.2, 78.8, 80.9, 82.7, 84.3, 85.8,
    87.2, 88.5, 89.7, 90.8, 91.9, 93.1, 93.9,
  ],
  C_95th: [
    21.7, 25.4, 28.8, 32.1, 35.8, 39.3, 42.8, 46.2, 49.6, 53, 56.3, 59.6, 62.6,
    65.9, 69, 72.1, 75.1, 77.8, 80.2, 82.8, 84.9, 86.7, 88.4, 90, 91.5, 93,
    94.2, 95.5, 96.5, 97.8, 98.6,
  ],
};

export const DV = {
  weeks: [
    21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39,
  ],
  C_5th: [
    0.32, 0.32, 0.32, 0.32, 0.32, 0.31, 0.31, 0.31, 0.3, 0.29, 0.28, 0.28, 0.27,
    0.26, 0.25, 0.24, 0.23, 0.22, 0.21,
  ],
  C_50th: [
    0.57, 0.57, 0.57, 0.57, 0.57, 0.57, 0.56, 0.56, 0.55, 0.54, 0.53, 0.53,
    0.52, 0.51, 0.5, 0.49, 0.48, 0.46, 0.45,
  ],
  "C_97.5th": [
    0.88, 0.88, 0.88, 0.88, 0.88, 0.87, 0.87, 0.86, 0.86, 0.85, 0.84, 0.83,
    0.82, 0.81, 0.8, 0.79, 0.78, 0.77, 0.76,
  ],
};

export const Fetal_Biometry_AC = {
  weeks: [
    12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
    31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42,
  ],
  C_3rd: [
    50.5, 62, 72.9, 83.8, 94.3, 105.2, 115.6, 126.7, 137, 148.7, 157.7, 168.4,
    178, 187.9, 197.4, 207.8, 217.7, 228.1, 236.5, 245.8, 255, 263, 271.9,
    281.3, 288.1, 296.1, 302.4, 309.6, 314.4, 319.4, 324.4,
  ],
  C_50th: [
    59.1, 71.1, 82.7, 93.9, 105.5, 117.1, 128.2, 140, 150.6, 162.7, 172.8, 184,
    194.2, 204.9, 215, 226.3, 236.8, 247.9, 256.5, 266.8, 276.9, 285.1, 294,
    303.2, 310.2, 318.7, 325.1, 332.3, 337.3, 342.3, 347.5,
  ],
  C_95th: [
    66.7, 79.1, 91.2, 102.7, 115.4, 127.6, 139.3, 151.6, 162.4, 174.8, 185.9,
    197.6, 208.4, 219.9, 230.4, 242.6, 253.4, 265.2, 274.1, 285.1, 296, 304.5,
    313.3, 322.4, 329.6, 338.4, 345, 352.2, 357.3, 362.5, 367.8,
  ],
};

export const Cerebellar_Hypoplasia = {
  weeks: [
    15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33,
    34, 35, 36, 37, 38,
  ],
  C_5th: [
    1.42, 1.46, 1.52, 1.59, 1.68, 1.77, 1.88, 1.99, 2.12, 2.25, 2.39, 2.53,
    2.67, 2.82, 2.98, 3.19, 3.28, 3.44, 3.59, 3.78, 3.88, 4.01, 4.14, 4.27,
  ],
  C_50th: [
    1.58, 1.65, 1.73, 1.82, 1.92, 2.04, 2.16, 2.3, 2.44, 2.5, 2.74, 2.91, 3.07,
    3.24, 3.42, 3.59, 3.77, 3.95, 4.13, 4.31, 4.48, 4.65, 4.82, 4.99,
  ],
  C_95th: [
    1.74, 1.86, 1.96, 2.05, 2.17, 2.3, 2.45, 2.6, 2.76, 2.93, 3.1, 3.28, 3.47,
    3.66, 3.86, 4.06, 4.26, 4.47, 4.67, 4.88, 5.09, 5.3, 5.5, 5.71,
  ],
};

export const Fetal_Biometry_EFW = {
  weeks: [
    12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
    31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42,
  ],
  C_5th: [
    50, 64, 80, 100, 123, 151, 185, 226, 269, 325, 383, 452, 526, 616, 709, 819,
    940, 1072, 1195, 1343, 1493, 1642, 1797, 1965, 2097, 2257, 2399, 2534, 2648,
    2756, 2879,
  ],
  C_50th: [
    60, 77, 97, 121, 150, 185, 228, 279, 333, 401, 475, 562, 656, 768, 884,
    1022, 1170, 1339, 1494, 1680, 1868, 2047, 2233, 2430, 2593, 2781, 2949,
    3107, 3242, 3372, 3515,
  ],
  C_95th: [
    71, 91, 114, 143, 179, 220, 272, 334, 398, 480, 570, 676, 790, 926, 1065,
    1232, 1408, 1613, 1799, 2025, 2249, 2456, 2671, 2896, 3087, 3300, 3491,
    3670, 3822, 3973, 4133,
  ],
};

export const Fetal_Biometry_FL = {
  weeks: [
    12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
    31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42,
  ],
  C_5th: [
    5.6, 8.8, 11.7, 14.8, 17.8, 20.6, 23.7, 26.7, 29.1, 31.9, 34.6, 37.1, 39.6,
    42.2, 44.6, 47, 49.4, 51.7, 53.7, 55.8, 57.8, 59.7, 61.4, 63, 64.4, 66,
    67.5, 68.5, 69.4, 70.1, 71.2,
  ],
  C_50th: [
    7.8, 11.1, 14, 17.2, 20.2, 23.1, 26.2, 29.2, 31.8, 34.5, 37.3, 39.9, 42.5,
    45.1, 47.5, 49.9, 52.3, 54.8, 57, 59.2, 61.2, 63.1, 64.9, 66.6, 68.2, 69.8,
    71.3, 72.4, 73.4, 74.2, 75.2,
  ],
  C_95th: [
    10.1, 13.4, 16.4, 19.6, 22.6, 25.5, 28.7, 31.8, 34.4, 37.2, 40, 42.7, 45.4,
    48, 50.4, 52.8, 55.3, 57.9, 60.3, 62.6, 64.6, 66.6, 68.5, 70.3, 72.1, 73.5,
    75.1, 76.2, 77.4, 78.2, 79.2,
  ],
};

export const Fetal_Biometry_HC = {
  weeks: [
    12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
    31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42,
  ],
  C_5th: [
    61.2, 75.1, 88.9, 102.8, 115.8, 128.8, 141.6, 153.8, 166.4, 178.3, 189.8,
    201.2, 211.4, 224, 233.8, 243.5, 253.3, 261.9, 269.5, 278.7, 285.6, 292.8,
    298.5, 304, 306.5, 309.6, 312.6, 314.1, 317, 318.5, 321.8,
  ],
  C_50th: [
    69, 83.3, 97.2, 111.2, 124.6, 137.9, 151.1, 163.7, 176.8, 188.9, 200.5,
    212.2, 222.9, 235.7, 245.7, 255.5, 265.3, 273.9, 281.5, 290.7, 297.6, 304.8,
    310.5, 316, 318.6, 321.6, 324.6, 326.1, 328.9, 331.1, 334.6,
  ],
  C_95th: [
    76.8, 91.5, 105.5, 119.7, 133.5, 147.1, 160.7, 173.7, 187.2, 199.5, 211.3,
    223.2, 234.3, 247.5, 257.6, 267.5, 277.3, 285.9, 293.5, 302.7, 309.6, 316.8,
    322.5, 328, 330.6, 333.6, 336.7, 338.2, 340.8, 343.7, 347.4,
  ],
};

export const Microcephaly_HC = {
  weeks: [
    12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
    31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42,
  ],
  C_5th: [
    61.2, 75.1, 88.9, 102.8, 117.7, 128.8, 141.6, 153.8, 166.4, 178.3, 189.8,
    201.2, 211.4, 224, 233.8, 243.5, 253.3, 261.9, 269.5, 278.7, 285.6, 292.8,
    298.5, 304, 306.5, 309.6, 312.6, 314.1, 317, 318.5, 321.8,
  ],
  C_50th: [
    69, 83.3, 97.2, 111.2, 124.6, 137.9, 151.1, 163.7, 176.8, 188.9, 200.5,
    212.2, 222.9, 235.7, 245.7, 255.5, 265.3, 273.9, 281.5, 290.7, 297.6, 304.8,
    310.5, 316, 318.6, 321.6, 324.6, 326.1, 328.9, 331.1, 334.6,
  ],
  C_95th: [
    76.8, 91.5, 105.5, 119.7, 133.5, 147.1, 160.7, 173.7, 187.2, 199.5, 211.3,
    223.2, 234.3, 247.5, 257.6, 267.5, 277.3, 285.9, 293.5, 302.7, 309.6, 316.8,
    322.5, 328, 330.6, 333.6, 336.7, 338.2, 340.8, 343.7, 347.4,
  ],
};

export const Oligo_AFI = {
  weeks: [
    14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32,
    33, 34, 35, 36, 37, 38, 39, 40, 41,
  ],
  C_5th: [
    2.8, 3.2, 3.6, 4.1, 4.6, 5.1, 5.5, 5.9, 6.3, 6.7, 7, 7.3, 7.5, 7.6, 7.6,
    7.6, 7.5, 7.3, 7.1, 6.8, 6.4, 6, 5.6, 5.1, 4.7, 4.2, 3.7, 3.3,
  ],
  C_50th: [
    5, 5.4, 5.8, 6.3, 6.8, 7.4, 8, 8.7, 9.3, 10, 10.7, 11.4, 12, 12.6, 13, 13.4,
    13.6, 13.6, 13.6, 13.3, 12.9, 12.4, 11.8, 11.1, 10.3, 9.4, 8.6, 7.8,
  ],
  C_95th: [
    8.6, 9.1, 9.6, 10.3, 11.1, 12, 12.9, 13.9, 14.9, 15.9, 16.9, 17.8, 18.7,
    19.4, 19.7, 20.4, 20.6, 20.6, 20.4, 20, 19.4, 18.7, 17.9, 16.7, 15.9, 14.9,
    13.9, 12.9,
  ],
};

export const Oligo_SLVP = {
  weeks: [
    14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32,
    33, 34, 35, 36, 37, 38, 39, 40, 41,
  ],
  C_5th: [
    1.7, 2, 2.3, 2.5, 2.7, 2.8, 2.9, 2.9, 3, 3, 3.1, 3, 3, 3, 3, 2.9, 2.9, 2.9,
    2.9, 2.9, 2.8, 2.8, 2.7, 2.6, 2.4, 2.3, 2.1, 1.9,
  ],
  C_50th: [
    2.9, 3.4, 3.6, 3.9, 4.1, 4.3, 4.4, 4.5, 4.6, 4.6, 4.7, 4.7, 4.8, 4.8, 4.8,
    4.8, 4.8, 4.8, 4.8, 4.8, 4.8, 4.7, 4.7, 4.5, 4.4, 4.2, 3.9, 3.7,
  ],
  C_95th: [
    5, 5.5, 5.9, 6.2, 6.4, 6.6, 6.7, 6.8, 6.8, 6.8, 6.8, 6.8, 6.8, 6.9, 6.9,
    6.9, 6.9, 7, 7.1, 7.2, 7.2, 7.2, 7.1, 7, 6.8, 6.6, 6.2, 5.7,
  ],
};

export const Trans_Cerebellar_Diameter = {
  weeks: [
    15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33,
    34, 35, 36, 37, 38, 39,
  ],
  C_5th: [
    10, 14, 16, 17, 18, 18, 19, 21, 22, 22, 23, 25, 26, 27, 29, 31, 32, 33, 32,
    33, 33, 36, 37, 40, 46,
  ],
  C_50th: [
    14, 16, 17, 18, 19, 20, 22, 23, 24, 25, 26, 29, 30, 31, 34, 35, 38, 38, 40,
    40, 40.5, 43, 45, 48.5, 52,
  ],
  C_95th: [
    16, 17, 18, 19, 22, 22, 24, 25, 26, 28, 29, 32, 32, 34, 38, 40, 43, 42, 44,
    44, 47, 55, 55, 55, 58,
  ],
};

export const Uterine_Artery = {
  weeks: [
    11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29,
    30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41,
  ],
  C_5th: [
    1.18, 1.11, 1.05, 0.99, 0.94, 0.89, 0.85, 0.81, 0.78, 0.74, 0.71, 0.69,
    0.66, 0.64, 0.62, 0.6, 0.58, 0.56, 0.55, 0.54, 0.52, 0.51, 0.5, 0.5, 0.49,
    0.48, 0.48, 0.47, 0.47, 0.47, 0.47,
  ],
  C_50th: [
    1.79, 1.68, 1.58, 1.49, 1.41, 1.33, 1.27, 1.2, 1.15, 1.1, 1.05, 1, 0.96,
    0.93, 0.89, 0.86, 0.84, 0.81, 0.79, 0.77, 0.75, 0.73, 0.71, 0.7, 0.69, 0.68,
    0.67, 0.66, 0.65, 0.65, 0.65,
  ],
  C_95th: [
    2.7, 2.53, 2.38, 2.24, 2.11, 1.99, 1.88, 1.79, 1.7, 1.61, 1.54, 1.47, 1.41,
    1.35, 1.3, 1.25, 1.21, 1.17, 1.13, 1.1, 1.06, 1.04, 1.01, 0.99, 0.97, 0.95,
    0.94, 0.92, 0.91, 0.9, 0.89,
  ],
};

export const Uterine_Artery_IP = {
  weeks: [
    11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29,
    30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41,
  ],
  C_5th: [
    1.18, 1.11, 1.05, 0.99, 0.94, 0.89, 0.85, 0.81, 0.78, 0.74, 0.71, 0.69,
    0.66, 0.64, 0.62, 0.6, 0.58, 0.56, 0.55, 0.54, 0.52, 0.51, 0.5, 0.5, 0.49,
    0.48, 0.48, 0.47, 0.47, 0.47, 0.47,
  ],
  C_50th: [
    1.79, 1.68, 1.58, 1.49, 1.41, 1.33, 1.27, 1.2, 1.15, 1.1, 1.05, 1, 0.96,
    0.93, 0.89, 0.86, 0.84, 0.81, 0.79, 0.77, 0.75, 0.73, 0.71, 0.7, 0.69, 0.68,
    0.67, 0.66, 0.65, 0.65, 0.65,
  ],
  C_90th: [
    2.7, 2.53, 2.38, 2.24, 2.11, 1.99, 1.88, 1.79, 1.7, 1.61, 1.54, 1.47, 1.41,
    1.35, 1.3, 1.25, 1.21, 1.17, 1.13, 1.1, 1.06, 1.04, 1.01, 0.99, 0.97, 0.95,
    0.94, 0.92, 0.91, 0.9, 0.86,
  ],
};

export const UA = {
  weeks: [
    20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38,
    39, 40,
  ],
  C_Plus_2_SD: [
    1.83, 1.63, 1.62, 1.36, 1.49, 1.45, 1.37, 1.41, 1.31, 1.49, 1.5, 1.25, 1.31,
    1.26, 1.15, 1.13, 1.29, 1.43, 1.21, 1.35, 1.07,
  ],
  C_Mean: [
    1.2871, 1.2625, 1.2379, 1.2133, 1.1887, 1.1641, 1.1395, 1.1149, 1.0903,
    1.0657, 1.0411, 1.0165, 0.9919, 0.9673, 0.9427, 0.9181, 0.8935, 0.8689,
    0.8443, 0.8197, 0.7951,
  ],
  C_Minus_2_SD: [
    0.79, 0.91, 0.94, 0.88, 0.93, 0.81, 0.85, 0.73, 0.79, 0.73, 0.58, 0.73,
    0.55, 0.58, 0.63, 0.69, 0.57, 0.47, 0.57, 0.67, 0.43,
  ],
};

export const MCA = {
  weeks: [
    20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38,
    39, 40,
  ],
  C_Plus_2_SD: [
    2.24, 2.19, 2.53, 1.97, 2.27, 2.85, 2.95, 3.54, 3.03, 2.64, 3, 2.83, 2.19,
    2.66, 2.33, 2.43, 2.34, 3.42, 2.26, 2.16, 1.71,
  ],
  C_Mean: [
    1.6183, 1.714, 1.7981, 1.8706, 1.9315, 1.9808, 2.0185, 2.0446, 2.0591,
    2.062, 2.0533, 2.033, 2.0011, 1.9576, 1.9025, 1.8358, 1.7575, 1.6676,
    1.5661, 1.453, 1.3283,
  ],
  C_Minus_2_SD: [
    1.28, 1.39, 1.21, 1.33, 1.43, 1.21, 1.23, 0.82, 1.39, 1.4, 1.68, 1.59, 1.43,
    1.14, 1.25, 1.19, 1.26, 0.7, 1.06, 1.12, 0.87,
  ],
};

export const CPR = {
  weeks: [
    20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38,
    39, 40,
  ],
  "+2 SD": [
    2.17, 1.94, 2.06, 1.95, 1.97, 2.79, 3.02, 3.34, 3.17, 2.72, 3.44, 2.97,
    2.99, 2.9, 3, 2.69, 2.93, 3.57, 2.72, 2.22, 2.68,
  ],
  Mean: [
    1.2364, 1.3775, 1.5068, 1.6243, 1.73, 1.8239, 1.906, 1.9763, 2.0348, 2.0815,
    2.1164, 2.1395, 2.1508, 2.1503, 2.138, 2.1139, 2.078, 2.0303, 1.9708,
    1.8995, 1.8164,
  ],
  "-2 SD": [
    0.57, 0.94, 0.9, 1.03, 1.09, 0.87, 0.82, 0.9, 1.09, 1, 1.24, 1.61, 1.07,
    1.3, 1.2, 1.33, 1.09, 0.93, 1.08, 1.06, 0.92,
  ],
};

export const NB = {
  weeks: [11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24],
  C_5th: [
    1.3, 1.5, 1.7, 2, 2.11, 1.99, 2.7, 2.98, 4.05, 4.2, 4.6, 4.6, 4.6, 4.89,
  ],
  C_50th: [1.8, 2.1, 2.4, 2.55, 3, 3.55, 4.65, 4.6, 5.3, 5.6, 5.8, 6, 6.2, 6.6],
  C_95th: [
    2.32, 2.9, 3.28, 3.51, 4.72,4.95, 5, 5.63, 6.3, 7.35, 7.15, 8, 7.36, 8.05,
  ],
};

export const IT = {
  weeks: [
    11.0, 11.1, 11.2, 11.3, 11.4, 11.5, 11.6, 12.0, 12.1, 12.2, 12.3, 12.4,
    12.5, 12.6, 13.0, 13.1, 13.2, 13.3, 13.4, 13.5, 13.6,
  ],
  C_5th: [
    1.4, 1.3, 1.3, 1.3, 1.3, 1.3, 1.4, 1.4, 1.5, 1.5, 1.5, 1.7, 1.8, 2, 2.1,
    2.3, 2.3, 2.5, 2.6, 2.6, 2.9,
  ],
  C_50th: [
    1.6, 1.6, 1.7, 1.7, 1.7, 1.8, 1.8, 1.9, 2, 2.1, 2.2, 2.3, 2.3, 2.6, 2.7,
    2.7, 2.9, 2.9, 3, 3, 3.1,
  ],
  C_95th: [
    1.9, 1.9, 1.9, 2.1, 2.1, 2.2, 2.3, 2.5, 2.7, 2.8, 2.8, 2.9, 3.1, 3.3, 3.5,
    3.5, 3.5, 3.5, 3.5, 3.5, 3.6,
  ],
};

export const NT = {
  weeks: [
    11.1, 11.2, 11.3, 11.4, 11.5, 11.6, 12.0, 12.1, 12.2, 12.3, 12.4, 12.5,
    12.6, 13.0, 13.1, 13.2, 13.3, 13.4, 13.5, 13.6,
  ],
  C_5th: [
    0.48, 0.53, 0.56, 0.6, 0.64, 0.68, 0.7, 0.74, 0.77, 0.8, 0.83, 0.86, 0.88,
    0.9, 0.92, 0.94, 0.96, 0.97, 0.98, 0.98,
  ],
  C_50th: [
    1.16, 1.23, 1.26, 1.32, 1.37, 1.42, 1.45, 1.49, 1.53, 1.57, 1.6, 1.63, 1.66,
    1.68, 1.7, 1.71, 1.72, 1.73, 1.73, 1.73,
  ],
  C_95th: [
    1.8, 1.88, 1.92, 1.99, 2.05, 2.11, 2.14, 2.19, 2.23, 2.27, 2.3, 2.33, 2.35,
    2.37, 2.38, 2.38, 2.38, 2.38, 2.37, 2.35,
  ],
};

export const CRL = {
  weeks: [11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24],
  crl: [
    1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0,
  ],
};
