import React, { useState, useEffect, useCallback, useRef, memo } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Fetal_Biometry_AC,
  Fetal_Biometry_BD,
  Fetal_Biometry_EFW,
  Fetal_Biometry_FL,
  Fetal_Biometry_HC,
  Trans_Cerebellar_Diameter,
  DV,
  Uterine_Artery,
  Oligo_SLVP,
  Oligo_AFI,
  UA,
  MCA,
  CPR,
  NB,
  IT,
  NT,
  CRL
} from "../utils/tablesData";

// Constants
const MEASUREMENT_CONFIG = {
  BPD: { min: 10, max: 150, unit: 'mm', chart: Fetal_Biometry_BD },
  HC: { min: 50, max: 400, unit: 'mm', chart: Fetal_Biometry_HC },
  AC: { min: 40, max: 400, unit: 'mm', chart: Fetal_Biometry_AC },
  FL: { min: 5, max: 90, unit: 'mm', chart: Fetal_Biometry_FL },
  EFW: { min: 100, max: 5000, unit: 'mm', chart: Fetal_Biometry_EFW },
  TCD: { min: 5, max: 60, unit: 'mm', chart: Trans_Cerebellar_Diameter },
  DV: { min: 0, max: 2, unit: 'mm', chart: DV },
  Uterine: { min: 0, max: 5, unit: 'mm', chart: Uterine_Artery },
  SLVP: { min: 0, max: 100, unit: 'mm', chart: Oligo_SLVP },
  AFI: { min: 0, max: 25, unit: 'mm', chart: Oligo_AFI },
  UA: { min: 0, max: 3, unit: 'mm', chart: UA },
  MCA: { min: 0, max: 3, unit: 'mm', chart: MCA },
  CPR: { min: 0, max: 3, unit: 'mm', chart: CPR },
  NB: { min: 0, max: 10, unit: 'mm', chart: NB },
  IT: { min: 0, max: 3, unit: 'mm', chart: IT },
  NT: { min: 0, max: 3, unit: 'mm', chart: NT },
  CRL: { min: 0, max: 3, unit: 'mm', chart: CRL },
};

const PATIENT_COLORS = {
  base: [
    { light: '#ffcccc', dark: '#ff9999' }, // Red variations
    { light: '#fffacc', dark: '#fff566' }, // Yellow variations
    { light: '#ccffcc', dark: '#99ff99' }, // Green variations
    { light: '#cce5ff', dark: '#99ccff' }, // Blue variations
    { light: '#e6ccb3', dark: '#cc9966' }, // Brown variations
    { light: '#ffcce6', dark: '#ff99cc' }, // Pink variations
    { light: '#e6ccff', dark: '#cc99ff' }, // Purple variations
    { light: '#ffccb3', dark: '#ff9966' }, // Orange variations
    { light: '#ccffff', dark: '#99ffff' }, // Cyan variations
    { light: '#ccccff', dark: '#9999ff' }  // Indigo variations
  ]
};


const getPatientColor = (patientName) => {
  if (!patientName) return PATIENT_COLORS.base[0];
  
  // Generate a consistent index based on patient name
  const hash = patientName.split('').reduce((acc, char) => {
    return char.charCodeAt(0) + ((acc << 5) - acc);
  }, 0);
  
  return PATIENT_COLORS.base[Math.abs(hash) % PATIENT_COLORS.base.length];
};

// Add this helper function at the top of the file, before NotificationSystem component
const convertWeeksDaysToDecimal = (weeks, days) => {
  if (!days) return weeks;
  // Convert days to decimal (e.g., 6 days = 0.6)
  const dayDecimal = Math.round((days / 10) * 10) / 10;
  return parseFloat((weeks + dayDecimal).toFixed(1));
};

// Notification Item Component
const NotificationItem = memo(({ notification, onClick }) => {
  const colors = getPatientColor(notification.patientName);
  
  return (
    <div
      onClick={() => onClick(notification)}
      className={`p-3 border-b cursor-pointer hover:opacity-80 transition-opacity`}
      style={{
        backgroundColor: notification.read ? colors.light : colors.dark,
      }}
    >
      <div className="flex justify-between items-start">
        <div>
          <div className="font-semibold">{notification.title}</div>
          <div className="text-sm text-gray-700">{notification.patientName}</div>
        </div>
        {!notification.read && (
          <span className="bg-blue-500 text-white text-xs px-2 py-1 rounded-full">
            New
          </span>
        )}
      </div>
      <div className="text-sm text-gray-600">{notification.message}</div>
      <div className="text-xs text-gray-400 mt-1">{notification.timestamp}</div>
    </div>
  );
});

const NotificationSystem = ({ 
  tableData = {}, 
  dueDate = {}, 
  onChartOpen, 
  patientName = '',
  onNotificationsChange,
  currentPatientId,
  patientDueDates 
}) => {
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const previousValuesRef = useRef({});
  const notificationRef = useRef(null);

  // Add a ref to track the current patient
  const currentPatientRef = useRef(null);

  // Reset previous values when patient changes
  useEffect(() => {
    if (currentPatientRef.current !== currentPatientId) {
      previousValuesRef.current = {}; // Reset previous values
      currentPatientRef.current = currentPatientId;
    }
  }, [currentPatientId]);

  const getChartData = useCallback((type, weeks, days) => {
    // console.log("🚀 ~ getChartData ~ type, weeks, days:", type, weeks, days)
    try {
      if (!type || !weeks) return null;
      
      const config = MEASUREMENT_CONFIG[type];
      if (!config?.chart?.weeks) return null;

    
      const gestationalAge = convertWeeksDaysToDecimal(weeks, days);
      // console.log("🚀 ~ getChartData ~ gestationalAge:", gestationalAge)
      
     
      if (type === 'NT' || type === 'IT') {
        // Find exact match first
        const exactIndex = config.chart.weeks.indexOf(gestationalAge);
        if (exactIndex !== -1) {
          return {
            upper: config.chart.C_95th[exactIndex],
            lower: config.chart.C_5th[exactIndex],
            mean: config.chart.C_50th[exactIndex],
            exactWeek: gestationalAge
            
          };
        }
        
       
        let closestIndex = 0;
        let smallestDiff = Math.abs(config.chart.weeks[0] - gestationalAge);
        
        for (let i = 1; i < config.chart.weeks.length; i++) {
          const diff = Math.abs(config.chart.weeks[i] - gestationalAge);
          if (diff < smallestDiff) {
            smallestDiff = diff;
            closestIndex = i;
          }
        }

        return {
          upper: config.chart.C_95th[closestIndex],
          lower: config.chart.C_5th[closestIndex],
          mean: config.chart.C_50th[closestIndex],
          exactWeek: gestationalAge
        };
      }

      // Original logic for other measurements
      const weekNumber = parseInt(weeks);
      const weekIndex = config.chart.weeks.indexOf(weekNumber);
      if (weekIndex === -1) return null;

      return {
        upper: type === 'DV' ? config.chart['C_97.5th']?.[weekIndex] :
               (config.chart.C_95th?.[weekIndex] || config.chart.C_Plus_2_SD?.[weekIndex]),
        lower: type === 'AC' ? config.chart.C_3rd?.[weekIndex] :
               config.chart.C_5th?.[weekIndex] || config.chart.C_Minus_2_SD?.[weekIndex],
        mean: config.chart.C_50th?.[weekIndex] || config.chart.Mean?.[weekIndex]
      };
    } catch (error) {
      console.error('Error getting chart data:', error);
      return null;
    }
  }, []);
           
  // Improved measurement validation
  const isValidMeasurement = useCallback((type, value) => {
    try {
      if (type === 'PatientID' || !value) return false;
      
      const numValue = typeof value === 'string' ? parseFloat(value) : value;
      if (isNaN(numValue)) return false;

      const config = MEASUREMENT_CONFIG[type];
      if (!config) return false;

      return numValue >= config.min && numValue <= config.max;
    } catch (error) {
      console.error('Error validating measurement:', error);
      return false;
    }
  }, []);

  // Improved bounds checking
  const isOutsideChartBounds = useCallback((type, value, weeks, days) => {
    try {
      if (!type || !value || !weeks || !isValidMeasurement(type, value)) return false;

      const chartData = getChartData(type, weeks, days);
      if (!chartData?.upper || !chartData?.lower) return false;

      const numValue = parseFloat(value);
      return numValue <= chartData.lower || numValue >= chartData.upper;
    } catch (error) {
      console.error('Error checking bounds:', error);
      return false;
    }
  }, [getChartData, isValidMeasurement]);

  // Improved notification creation
  const createNotification = useCallback((type, value, weeks, days) => {
    try {
      const chartData = getChartData(type, weeks, days);
      if (!chartData) return null;

      const numValue = parseFloat(value);
      const config = MEASUREMENT_CONFIG[type];
      const direction = numValue > chartData.upper ? 'above' : 'below';
      
      // Calculate exact week for NT and IT
      const exactWeek = (type === 'NT' || type === 'IT') 
        ? convertWeeksDaysToDecimal(weeks, days)
        : weeks;

      // Format the date as dd/mm/yyyy
      const now = new Date();
      const formattedDate = new Intl.DateTimeFormat('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      }).format(now);

      // Format the time as hh:mm:ss AM/PM
      const formattedTime = now.toLocaleTimeString('en-GB', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true,
      });

      // Special messages for NB, NT, IT
      let specialMessage = null;
      if (['NB', 'NT', 'IT'].includes(type)) {
        specialMessage = {
          NB: 'Nasal Bone - Please use further information',
          NT: 'Nuchal Translucency - Please use further information',
          IT: 'Intracranial Translucency - Please use further information'
        }[type];
      }

      const notification = {
        id: `${type}-${value}-${exactWeek}-${Date.now()}`,
        type,
        value,
        patientName,
        patientId: currentPatientId,
        exactWeek,
        title: `Abnormal ${type} Measurement`,
        message: `${type} measurement (${value}${config.unit}) is ${direction} normal range`,
        severity: 'high',
        timestamp: `${formattedDate}, ${formattedTime}`,
        read: false,
        specialMessage
      };

      // console.log("Created notification:", notification);
      return notification;
    } catch (error) {
      console.error('Error creating notification:', error);
      return null;
    }
  }, [getChartData, patientName, currentPatientId]);

  // Event handlers
  const handleNotificationClick = useCallback((notification) => {
    // console.log("🚀 ~ handleNotificationClick ~ notification:", notification)
    if (!notification?.id || !onChartOpen) return;

    setNotifications(prev =>
      prev.map(notif =>
        notif.id === notification.id ? { ...notif, read: true } : notif
      )
    );
    
    setUnreadCount(prev => Math.max(0, prev - (!notification.read ? 1 : 0)));
    setIsOpen(false);
    
    // Pass the exact week for precise plotting
    onChartOpen(notification.type, notification.value, notification.exactWeek);
  }, [onChartOpen]);

  const clearNotifications = useCallback(() => {
    previousValuesRef.current = tableData;
    // setLastClearedTime(Date.now());
    setNotifications([]);
    setUnreadCount(0);
    setIsOpen(false);
    toast.dismiss();
  }, [tableData]);

  // Click outside handler
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (notificationRef.current && !notificationRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Update notifications state and notify parent
  const updateNotifications = useCallback((newNotifications) => {
    // console.log("Updating notifications:", newNotifications);
    setNotifications(newNotifications);
    onNotificationsChange?.(newNotifications);
  }, [onNotificationsChange]);

  // Modify the useEffect that processes measurements
  useEffect(() => {
    if (!tableData || Object.keys(tableData).length === 0) {
      return;
    }

    try {
      const newNotifications = [];
      const currentIds = new Set(notifications.map(n => n.id));
      const patientDueDate = currentPatientId ? (patientDueDates[currentPatientId] || dueDate) : dueDate;
      
      if (!patientDueDate?.weeks) {
        return;
      }

      Object.entries(tableData).forEach(([type, value]) => {
        if (!value || value === "" || type === 'PatientID') return;

        // Check if the value has changed from the previous value
        const prevValue = previousValuesRef.current[type];
        const valueChanged = prevValue !== value;
        
        // Process if value changed or if it's a new patient
        if (valueChanged) {
          if (isValidMeasurement(type, value)) {
            if (isOutsideChartBounds(type, value, patientDueDate.weeks, patientDueDate.days)) {
              const notificationId = `${type}-${value}-${patientDueDate.weeks}-${currentPatientId || 'manual'}-${Date.now()}`;
              
              if (!currentIds.has(notificationId)) {
                const notification = createNotification(type, value, patientDueDate.weeks, patientDueDate.days);
                if (notification) {
                  notification.id = notificationId;
                  newNotifications.push(notification);
                }
              }
            }
          }
        }
      });

      // Update previous values for current patient
      previousValuesRef.current = { ...tableData };

      if (newNotifications.length > 0) {
        const updatedNotifications = [...newNotifications, ...notifications];
        setNotifications(updatedNotifications);
        setUnreadCount(prev => prev + newNotifications.length);
        
        onNotificationsChange?.(updatedNotifications);

        newNotifications.forEach(notification => {
          if (!toast.isActive(notification.id)) {
            toast.warning(notification.message, {
              toastId: notification.id,
              onClick: () => handleNotificationClick(notification),
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: true
            });
          }
        });
      }
    } catch (error) {
      console.error('Error processing notifications:', error);
    }
  }, [tableData, dueDate?.weeks, dueDate?.days, notifications, createNotification, handleNotificationClick, onNotificationsChange, currentPatientId, patientDueDates]);

  // Add effect to clean up abnormal measurements when notifications are cleared
  // useEffect(() => {
  //   if (notifications.length === 0) {
  //     setAbnormalMeasurements([]);
  //   }
  // }, [notifications]);

  // Export abnormal measurements to parent
  // useEffect(() => {
  //   // You can add a prop like onAbnormalMeasurementsChange if needed
  //   // console.log('Current abnormal measurements:', abnormalMeasurements);
  // }, [abnormalMeasurements]);

  return (
    <div className="relative" ref={notificationRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="p-2 relative"
        aria-label="Notifications"
      >
        <svg
          className="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
          />
        </svg>
        {unreadCount > 0 && (
          <span className="absolute top-0 right-0 bg-red-500 text-white rounded-full w-5 h-5 text-xs flex items-center justify-center">
            {unreadCount}
          </span>
        )}
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-2 w-80 bg-white rounded-lg shadow-xl z-50">
          <div className="p-2 border-b flex justify-between items-center">
            <h3 className="font-semibold">
              Notifications ({notifications.length})
              {unreadCount > 0 && ` • ${unreadCount} unread`}
            </h3>
            {notifications.length > 0 && (
              <button
                onClick={clearNotifications}
                className="text-sm text-gray-500 hover:text-gray-700"
              >
                Clear all
              </button>
            )}
          </div>
          <div className="max-h-96 overflow-y-auto">
            {notifications.length === 0 ? (
              <div className="p-4 text-center text-gray-500">
                No notifications
              </div>
            ) : (
              notifications.map(notification => (
                <NotificationItem
                  key={notification.id}
                  notification={notification}
                  onClick={handleNotificationClick}
                />
              ))
            )}
          </div>
        </div>
      )}
    </div>
  );
};

NotificationSystem.propTypes = {
  tableData: PropTypes.object,
  dueDate: PropTypes.shape({
    weeks: PropTypes.number,
    days: PropTypes.number,
    dueDate: PropTypes.string
  }),
  onChartOpen: PropTypes.func.isRequired
};

export default NotificationSystem;